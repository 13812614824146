import style from "./Seleccion.module.css"
import { useState } from "react"
import { useNavigate } from 'react-router-dom'
import logo from "../../assets/Logo.svg"
import campo from "../../assets/CampoLimpio.png"
import { opcionesPosiciones } from "../funciones"

export default function Posicion() {
    const [posicion, setPosicion] = useState("")
    const usuario = JSON.parse(localStorage.getItem("dataUser"))
    const [aviso, setAviso] = useState("")


    const navigate = useNavigate();

    const continuar = () => {
        if(posicion === "") {
            setAviso("Debes seleccionar una posición para continuar")
            return
        }
        usuario.posicion = posicion
        localStorage.setItem("dataUser", JSON.stringify(usuario))
        navigate("/extra")
    }

    const regresar = () => {
        navigate("/basica");
    }

    const onClickHome = () => {
        navigate("/")
    }

    return (
        <div className={style.container}>
            <div className={style.general}>
                <div className={style.contLogo}>
                    <img className={style.logo} src={logo} alt="Big Sport Data" />
                </div>
                <div className={style.posicion}>
                    <div className={style.inter}>
                        <h1 className={style.regName}>POSICIÓN DE JUEGO</h1>
                        <div className={style.conText}>
                            <span className={style.linkText}>
                                Compártenos en que posición del campo tienes tu mejor desempeño y que otras posiciones te gusta jugar.
                            </span>
                        </div>
                        <img src={campo} alt="posicion" className={style.campo} />
                        <div className={style.contOpciones}>
                            <h2 className={style.selectOpc}>1. SELECCIONA TU POSICIÓN FAVORITA PARA JUGAR</h2>
                            <div className={style.opciones}>
                                {opcionesPosiciones.map((posicionOpc, index) => (
                                    <div key={index} className={style.opcion}>
                                        <div className={style.contCheck}>
                                            <label htmlFor={posicionOpc} className={style.labelCheckbox}>
                                                <input
                                                    type="checkbox"
                                                    className={style.checkbox}
                                                    id={posicionOpc}
                                                    onChange={() => setPosicion(posicionOpc)}
                                                    checked={posicionOpc === posicion}
                                                />
                                                <span className={style.textCheck}>
                                                    {posicionOpc}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <h2 className={style.selectOpcTwo}>2. SELECCIONA OTRAS POSICIONES DONDE JUEGUES</h2>
                        </div>
                        <span className={style.avisoB}>{aviso}</span>
                        <div className={style.contB}>
                            <button
                                className={style.btnRegresar}
                                onClick={() => regresar()}
                            >
                                Regresar
                            </button>
                            <button
                                className={style.btnContinuar}
                                onClick={continuar}
                            >
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
                <p className={style.copyright}>© 2024. Big Sport Data Todos los derechos reservados.</p>
            </div>
        </div>
    )
}