import React, { useEffect } from 'react'
import styles from "./ModalNotificaciones.module.css"
import Notis from '../Notis/Notis'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { getVisitorPublicaciones, deleteNotificaciones } from "../../actions/index"


const ModalNotificaciones = ({clickMessage}) => {
    const notificaciones = useSelector(state => state.notificaciones)

    const dispatch = useDispatch();

    const dataUserLocal = JSON.parse(localStorage.getItem("dataUser"))

    const sortedNotificaciones = [...notificaciones].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    useEffect(() => {
        dispatch(deleteNotificaciones(dataUserLocal.id))
    },[])

    console.log(sortedNotificaciones)

    return (
<div className={[styles.container, "modal-notificaciones"].join(" ")}>
            <h2 className={styles.title}>NOTIFICACIONES</h2>
            {sortedNotificaciones.length > 0 ? sortedNotificaciones.map((noti) => {
                return <Notis
                    key={noti.id}
                    id={noti.id}
                    mensaje={noti.mensaje}
                    link={noti.link}
                    icono={noti.icono}
                    tipo={noti.tipo}
                    createdAt={noti.createdAt}
                    nombre={noti.nombre}
                    leido={noti.leido}
                    clickMessage={clickMessage}/>
            }) : <p className={styles.aviso}>No tienes notificaciones nuevas</p>}
        </div>
    );
}

export default ModalNotificaciones
