import React from 'react';
import styles from './Alert.module.css';
const Alert = ({borrarLista, setIdLista}) => {

    return (
        <div className={styles.conteiner}>
            <div className={styles.contMenu }>
                <h1 className={styles.title}>Eliminar lista</h1>
                <p className={styles.control}>Estas seguro de eliminar esta lista?</p>
                {/* <p className={styles.aviso}>¡Si continuas no podras recuperar la información en el futuro!</p> */}
                <p className={styles.aviso}>¡El sistema creara una lista automatica si no tienes listas!</p>
               <div className={styles.botones}>
               <button className={styles.btnAgregar} onClick={() => borrarLista()}>Aceptar</button>
               <button className={styles.btnCancelar} onClick={() =>setIdLista("")}>Cancelar</button>
               </div>
            </div>
        </div>
    );
};

export default Alert;

