import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './getCroppedImage'; // Función para generar la imagen recortada
import './styles.css'; // Asegúrate de agregar estilos básicos para el Cropper

const ImageEditor = () => {
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  // Manejar la subida de la imagen
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader.result);
      reader.readAsDataURL(file);
    }
  };

  // Guardar las coordenadas del área de recorte
  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Generar la imagen recortada
  const handleCrop = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      setCroppedImage(croppedImage); // Puedes usar este resultado para mostrar o enviar a Cloudinary
    } catch (e) {
      console.error(e);
    }
  }, [image, croppedAreaPixels]);

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h2>Editor de Imagen</h2>
      {!image && (
        <input type="file" accept="image/*" onChange={handleImageUpload} />
      )}
      {image && (
        <>
          <div className="crop-container">
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1} // Relación de aspecto (1:1 para cuadrado)
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div>
            <button onClick={handleCrop}>Recortar</button>
          </div>
        </>
      )}
      {croppedImage && (
        <div className="cropped-image-container">
          <h3>Imagen Recortada</h3>
          <img
            src={croppedImage}
            alt="Cropped"
          />
        </div>
      )}
    </div>
  );
};

export default ImageEditor;
