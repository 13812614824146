import React, { useState, useEffect } from "react";
import style from "./ModalEntrenamiento.module.css";
import closeIcon from "../../assets/Vector2.png";
import backIcon from "../../assets/isquierda.png";
import nextIcon from "../../assets/derecha.png";
import notIcont from "../../assets/x21.png";
import balonImage from "../../assets/balonMetrica.svg";
import { Bar } from "react-chartjs-2";
import { URL } from "../../link.js";
import { useDispatch } from "react-redux";
import { getEntrenamientos } from "../../actions/index.js";
import axios from "axios";
const ModalEntrenamiento = ({
  setModalEntrenamientoBool,
  entrenamientos,
  user,
}) => {
  const [agregarBool, setAgregarBool] = useState(false);
  const [tipo, setTipo] = useState("Entrenamiento");
  const [tiempo, setTiempo] = useState(null);
  const [arrayEntrenamientos, setArrayEntrenamientos] =
    useState(entrenamientos);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [tiempoDia, setTiempoDia] = useState(0);
  const [tiempoMesEntrenamiento, setTiempoMesEntrenamiento] = useState(0);
  const [tiempoMesJugado, setTiempoMesJugado] = useState(0);
  const [goles, setGoles] = useState(null);
  const [hasUploadedMetric, setHasUploadedMetric] = useState(false);
  const [rachaActual, setRachaActual] = useState(0);
  const [mensaje, setMensaje] = useState("");
  const [golesMes, setGolesMes] = useState(0);
  const [rachaMaxima, setRachaMaxima] = useState(0);
  const labels = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];
  const [data, setData] = useState({
    labels: labels,
    datasets: [
      {
        label: "Entrenamientos",
        data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
        backgroundColor: labels.map(() => "rgba(228, 253, 63, 0.37)"),
        borderColor: "rgba(75, 192, 192, 0)",
        borderWidth: 1,
      },
    ],
  });

  const dispatch = useDispatch();

  useEffect(() => {
    generateDays(currentDate);
  }, [currentDate, entrenamientos]);
  const generateDays = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();

    // Normalizar 'today' para eliminar la información de tiempo
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Establecer horas, minutos y segundos en 0

    const days = [];
    const daysInCurrentMonth = new Date(year, month + 1, 0).getDate();

    for (let i = 1; i <= daysInCurrentMonth; i++) {
      const currentDay = new Date(year, month, i);
      currentDay.setHours(0, 0, 0, 0); // Normalizar la fecha a medianoche

      // Buscar si hay un entrenamiento en esta fecha
      const entrenamiento = entrenamientos.find((ent) => {
        const entrenamientoDate = new Date(ent.createdAt);
        entrenamientoDate.setHours(0, 0, 0, 0); // Normalizar fecha del entrenamiento
        return entrenamientoDate.getTime() === currentDay.getTime();
      });

      // Clasificar el día
      if (currentDay < today) {
        days.push({
          date: currentDay,
          status: "past",
          entrenamiento: entrenamiento || null,
        });
      } else if (currentDay.getTime() === today.getTime()) {
        days.push({
          date: currentDay,
          status: "current",
          entrenamiento: entrenamiento || null,
        });
        console.log("Día actual encontrado:", currentDay);
      } else {
        days.push({ date: currentDay, status: "future" });
      }
    }

    setDaysInMonth(days);
  };

  const handleMonthChange = (direction) => {
    setCurrentDate((prevDate) => {
      const newMonth =
        direction === "prev"
          ? prevDate.getMonth() - 1
          : prevDate.getMonth() + 1;
      return new Date(prevDate.getFullYear(), newMonth, 1);
    });
  };

  function handleDayClick(selectedDate) {
    console.log("Fecha seleccionada:", selectedDate);
    setSelectedDate(selectedDate);
  }

  const opciones = {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          color: "rgba(21, 26, 32, 1)",
          display: false,
        },
        ticks: {
          color: "rgba(255, 255, 255, 1)",
        },
      },
      y: {
        grid: {
          color: "rgba(21, 26, 32, 1)",
          display: false,
        },
        ticks: {
          color: "rgba(255, 255, 255, 1)",
          display: false,
        },
      },
    },
  };

  const getTiempoDia = () => {
    const entrenamiento = arrayEntrenamientos.find(
      (ent) =>
        new Date(ent.createdAt).toDateString() === selectedDate.toDateString()
    );
    if (entrenamiento) {
      setTiempoDia(entrenamiento.tiempo);
    } else {
      setTiempoDia(0);
    }
  };

  const getTiempoMesEntrenamiento = () => {
    const entrenamientos = arrayEntrenamientos.filter((ent) => {
      const date = new Date(ent.createdAt);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      let selectedMonth = selectedDate.getMonth() + 1;
      let selectedYear = selectedDate.getFullYear();
      return (
        month === selectedMonth &&
        year === selectedYear &&
        ent.tipo === "Entrenamiento"
      );
    });
    const tiempoTotal = entrenamientos.reduce(
      (acc, ent) => acc + ent.tiempo,
      0
    );
    setTiempoMesEntrenamiento(tiempoTotal);
  };

  const getTiempoMesJugado = () => {
    const entrenamientos = arrayEntrenamientos.filter((ent) => {
      const date = new Date(ent.createdAt);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      let selectedMonth = selectedDate.getMonth() + 1;
      let selectedYear = selectedDate.getFullYear();
      return (
        month === selectedMonth &&
        year === selectedYear &&
        ent.tipo === "Partido"
      );
    });
    const tiempoTotal = entrenamientos.reduce(
      (acc, ent) => acc + ent.tiempo,
      0
    );
    setTiempoMesJugado(tiempoTotal);
  };

  const obtenerGolesDelMes = () => {
    // Obtén el mes y año seleccionados
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth(); // Meses son 0-indexados (enero = 0)

    // Filtra los entrenamientos del mes seleccionado
    const golesDelMes = entrenamientos
      .filter((ent) => {
        const fechaEntrenamiento = new Date(ent.createdAt);
        return (
          fechaEntrenamiento.getFullYear() === year &&
          fechaEntrenamiento.getMonth() === month &&
          ent.goles !== null // Aseguramos que tenga un valor válido
        );
      })
      .reduce((total, ent) => total + ent.goles, 0); // Suma los goles del mes

    // Actualiza el estado con los goles totales del mes
    setGolesMes(golesDelMes);
  };

  const actualizarColorMes = () => {
    if (selectedDate) {
      const mesSeleccionado = selectedDate.getMonth(); // Índice del mes seleccionado (0 = Enero)
      const nuevoDataset = { ...data.datasets[0] }; // Copia del dataset
      nuevoDataset.backgroundColor = labels.map((_, index) =>
        index === mesSeleccionado
          ? "rgba(228, 253, 63, 1)"
          : "rgba(228, 253, 63, 0.37)"
      ); // Cambia solo el color del mes seleccionado
      setData({ ...data, datasets: [nuevoDataset] });
    }
  };

  function actualizarDatos() {
    // Obtener el año seleccionado a partir de selectedDate
    const selectedYear = new Date(selectedDate).getFullYear();

    // Inicializar un array con 12 elementos en 0 para los meses del año
    const datosPorMes = new Array(12).fill(0);

    // Recorrer el array de entrenamientos
    arrayEntrenamientos.forEach((entrenamiento) => {
      // Convertir la fecha de creación a un objeto Date
      const fecha = new Date(entrenamiento.createdAt);

      // Verificar que el registro corresponde al año seleccionado
      if (fecha.getFullYear() === selectedYear) {
        // Obtener el índice del mes (0 = Enero, 11 = Diciembre)
        const mes = fecha.getMonth();

        // Sumar los minutos al mes correspondiente
        datosPorMes[mes] += entrenamiento.tiempo;
      }
    });

    // Actualizar el estado de data con los nuevos valores
    setData((prevData) => ({
      ...prevData,
      datasets: [
        {
          ...prevData.datasets[0],
          data: datosPorMes, // Actualizamos la propiedad 'data' con los nuevos valores
        },
      ],
    }));
  }

  const postEntrenamiento = async () => {
    let data = {
      tipo: tipo,
      tiempo: tiempo,
      entreno: true,
      goles: goles,
      userId: user.id,
    };
    try {
      const response = await axios.post(`${URL}/entrenamiento/nuevo`, data);
      console.log(response);
      setArrayEntrenamientos(response.data);
      dispatch(getEntrenamientos(user.id));
      setSelectedDate(new Date());
      setAgregarBool(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    if (hasUploadedMetric) {
      setMensaje("Ya subiste una métrica el día de hoy.");
    } else {
      setAgregarBool(true);
      setMensaje("");
    }
  };

  function getRachaActual() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    const sortedEntrenamientos = entrenamientos
      .map((ent) => ({
        ...ent,
        normalizedDate: new Date(new Date(ent.createdAt).setHours(0, 0, 0, 0)),
      }))
      .sort((a, b) => b.normalizedDate - a.normalizedDate);
  
    let streak = 0;
    let currentDate = today;
  
    for (const ent of sortedEntrenamientos) {
      if (ent.normalizedDate.getTime() === currentDate.getTime()) {
        streak++;
        currentDate.setDate(currentDate.getDate() - 1);
      } else if (ent.normalizedDate < currentDate) {
        break;
      }
    }
    setRachaActual(streak);
  }

  function getRachaMaxima() {
    const sortedEntrenamientos = entrenamientos
      .map((ent) => ({
        ...ent,
        normalizedDate: new Date(new Date(ent.createdAt).setHours(0, 0, 0, 0)),
      }))
      .sort((a, b) => a.normalizedDate - b.normalizedDate); // Ordenar en orden ascendente
  
    let maxStreak = 0;
    let currentStreak = 0; // Inicia en 1 porque contamos el primer día como parte de una racha
  
    for (let i = 1; i < sortedEntrenamientos.length; i++) {
      const prevDate = sortedEntrenamientos[i - 1].normalizedDate;
      const currentDate = sortedEntrenamientos[i].normalizedDate;
  
      // Si la fecha actual es el día siguiente de la anterior, incrementar la racha actual
      if (
        (currentDate - prevDate) / (1000 * 60 * 60 * 24) === 1 // Diferencia de 1 día
      ) {
        currentStreak++;
      } else if (currentDate > prevDate) {
        // Si no son consecutivos, reinicia la racha actual
        maxStreak = Math.max(maxStreak, currentStreak); // Actualiza la racha máxima
        currentStreak = 1; // Reinicia la racha actual
      }
    }
  
    // Comparar la última racha con la máxima
    maxStreak = Math.max(maxStreak, currentStreak);
  
    // Asignar directamente el valor calculado de la racha máxima
    setRachaMaxima(maxStreak);
  }

  useEffect(() => {
    getRachaActual();
    getRachaMaxima();
  }, [entrenamientos]);

  useEffect(() => {
    getTiempoDia();
    getTiempoMesEntrenamiento();
    getTiempoMesJugado();
    actualizarColorMes();
    actualizarDatos();
    obtenerGolesDelMes();
  }, [selectedDate]);

  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalizar a medianoche

    // Verificar si hay una métrica con fecha de hoy
    const metricUploadedToday = entrenamientos.some((ent) => {
      const metricDate = new Date(ent.createdAt);
      metricDate.setHours(0, 0, 0, 0); // Normalizar fecha del entrenamiento
      return metricDate.getTime() === today.getTime();
    });

    setHasUploadedMetric(metricUploadedToday);
  }, [entrenamientos]);

  console.log(arrayEntrenamientos);

  return (
    <div className={style.modalEntrenamiento}>
      <div className={style.modalContent}>
        <div className={style.calendarioCont}>
          <div className={style.fechaCont}>
            <p className={style.fechaCalendario}>
              {currentDate.toLocaleDateString("es-ES", {
                year: "numeric",
                month: "long",
              })}
            </p>
          </div>
          <div className={style.semana}>
            {["D", "L", "M", "M", "J", "V", "S"].map((dia, index) => (
              <div key={index} className={style.diaSemana}>
                {dia}
              </div>
            ))}
          </div>
          <div className={style.calendario}>
            
              {Array.from({
                length: new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth(),
                  1
                ).getDay(),
              }).map((_, index) => (
                <div key={`empty-${index}`} className={style.placeholder}></div>
              ))}

              {/* Renderización de días del mes */}
              {daysInMonth.map((day, index) => (
                <div key={index} className={style.dia}>
                  <p className={style.diaNum}>{day.date.getDate()}</p>
                  <div className={style.contDia}>
                    {day.status === "past" || day.status === "current" ? (
                      day.entrenamiento ? (
                        <div
                          className={style.contCirculo}
                          onClick={() => handleDayClick(day.date)}
                        >
                          <p className={style.valorM}>
                            {day.entrenamiento.tiempo}"
                          </p>
                          {day.entrenamiento.goles !== null && (
                            <img
                              src={balonImage}
                              alt="balon"
                              className={style.balon}
                            />
                          )}
                        </div>
                      ) : day.status === "current" ? (
                        <div className={style.divCirculoCurrent}>
                          <p className={style.valorC}>{tiempo}</p>
                        </div>
                      ) : (
                        <img
                          src={notIcont}
                          alt="No entreno"
                          className={style.iconoNoEntreno}
                        />
                      )
                    ) : (
                      <div className={style.divFuture}></div>
                    )}
                  </div>
                </div>
              ))}
            
          </div>

          <div className={style.controlesCont}>
            <img
              className={style.controlIcon}
              src={backIcon}
              alt="back"
              onClick={() => handleMonthChange("prev")}
            />
            <img
              className={style.controlIcon}
              src={nextIcon}
              alt="next"
              onClick={() => handleMonthChange("next")}
            />
          </div>
        </div>
        {!agregarBool ? (
          <div className={style.metricasCont}>
            <p
              className={style.btnCerrar}
              onClick={() => setModalEntrenamientoBool(false)}
            >
              X
            </p>
            <p
              className={style.title}
            >{`Estadísticas del día: ${selectedDate.toLocaleDateString(
              "es-ES",
              {
                day: "numeric",
                year: "numeric",
                month: "long",
              }
            )}`}</p>
            <p className={style.subTitle}>MINUTOS ENTRENADOS</p>
            <div className={style.contMetricaDia}>
              <p className={style.minutos}>{tiempoDia}</p>
              <span className={style.unidad}>minutos</span>
            </div>
            <p className={style.title}>Estadísticas del mes</p>
            <div className={style.contMetricaMes}>
              <div className={style.contMetrica}>
                <p className={style.subTitle}>MINUTOS ENTRENADOS</p>
                <div className={style.contMetricaDia}>
                  <p className={style.minutos}>{tiempoMesEntrenamiento}</p>
                  <span className={style.unidad}>minutos</span>
                </div>
              </div>
              <div className={style.contMetrica}>
                <p className={style.subTitle}>MINUTOS JUGADOS</p>
                <div className={style.contMetricaDia}>
                  <p className={style.minutos}>{tiempoMesJugado}</p>
                  <span className={style.unidad}>minutos</span>
                </div>
              </div>
              <div className={style.contMetrica}>
                <p className={style.subTitle}>GOLES DEL MES</p>
                <div className={style.contMetricaDia}>
                  <p className={style.minutos}>{golesMes}</p>
                  <span className={style.unidad}>goles</span>
                </div>
              </div>
            </div>
            <p className={style.subTitle}>ENTRENAMIENTO ANUAL</p>
            <div className={style.contGrafica}>
              <Bar data={data} options={opciones} />
            </div>
            <button className={style.btnAgregar} onClick={() => handleClick()}>
              Agregar métricas del día
            </button>
            <span className={style.mensaje}>{mensaje}</span>
          </div>
        ) : (
          <div className={style.agregarMetricaCont}>
            <p
              className={style.btnCerrar}
              onClick={() => setModalEntrenamientoBool(false)}
            >
              X
            </p>
            <p className={style.title}>RACHAS</p>
            <div className={style.contRachas}>
              <div className={style.racha}>
                <p className={style.txt}>Racha actual</p>
                <div className={style.contValor}>
                  <p className={style.valor}>{rachaActual} dias</p>
                </div>
              </div>
              <div className={style.racha}>
                <p className={style.txt}>Racha maxima</p>
                <div className={style.contValorM}>
                  <p className={style.valor}>{rachaMaxima} dias</p>
                </div>
              </div>
            </div>
            <p className={style.subTitle}>Guardad estadísticas del día</p>
            <div className={style.contSelect}>
              <div
                className={
                  tipo === "Entrenamiento" ? style.selected : style.notSelected
                }
                onClick={
                  tipo === "Entrenamiento"
                    ? null
                    : () => setTipo("Entrenamiento")
                }
              >
                <p
                  className={
                    tipo === "Entrenamiento"
                      ? style.txtSelect
                      : style.txtNotSelect
                  }
                >
                  Entrenamiento
                </p>
              </div>
              <div
                className={
                  tipo === "Partido" ? style.selected : style.notSelected
                }
                onClick={tipo === "Partido" ? null : () => setTipo("Partido")}
              >
                <p
                  className={
                    tipo === "Partido" ? style.txtSelect : style.txtNotSelect
                  }
                >
                  Partido
                </p>
              </div>
            </div>
            <p className={style.subTitle}>Tiempo total de entrenamiento</p>
            {tipo === "Entrenamiento" ? (
              <div className={style.contInput}>
                <input
                  className={style.inp}
                  type="text"
                  name="tiempo"
                  placeholder="00"
                  onChange={(e) => setTiempo(e.target.value)}
                  id=""
                  value={tiempo}
                />
                <span className={style.unidad}>minutos</span>
              </div>
            ) : (
              <div className={style.contInput}>
                <input
                  className={style.inp2}
                  type="text"
                  name="tiempo"
                  placeholder="00"
                  onChange={(e) => setTiempo(e.target.value)}
                  id=""
                  value={tiempo}
                />
                <span className={style.unidad2}>minutos</span>
                <input
                  className={style.inp2}
                  type="text"
                  name="goles"
                  placeholder="0"
                  onChange={(e) => setGoles(e.target.value)}
                  id=""
                  value={goles}
                />
                <span className={style.unidad2}>goles</span>
              </div>
            )}
            <button className={style.btnAgregar} onClick={postEntrenamiento}>
              Guardar
            </button>
            <button
              className={style.btnCancelar}
              onClick={() => setAgregarBool(false)}
            >
              Cancelar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalEntrenamiento;
