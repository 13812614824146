import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { URL } from '../../link.js';

const Validar = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const validarUsuario = async (id) => {
        try {
            const res = await axios.put(`${URL}/usuario/validar/${id}`);
            console.log(res);
            if (res.data) {
                localStorage.setItem("dataUser", JSON.stringify(res.data))
              
                navigate("/seleccion");
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        validarUsuario(id);
    }, [id]);

    return (
        <div>
            <h1>Validando registro. Por favor espere...</h1>
        </div>
    );
}

export default Validar;
