import React from 'react'
import style from './Otro.module.css'
import { useSelector } from "react-redux"

const Otro = ({ inAmigos, user, isVisitorBool }) => {

    const seguidores = useSelector(state => state.seguidores)
    const seguidos = useSelector(state => state.seguidos)

    return (
        <div className={style.conteiner}>
            <div className={style.contUser}>
                <div className={style.user}>
                    <img className={style.foto} src={user.foto} alt="" />
                    <h3 className={style.nombre}>{user.nombre}</h3>
                    <p className={style.ubicacion}>{user.direccion}, {user.origen}</p>
                    <p className={style.ubicacion}>{user.fondo || "Sin equipo"}</p>
                </div>
                <div className={style.info}>
                    <h4 className={style.subT}>INTERÉS</h4>
                    <div className={style.contRol}>
                        <p className={style.rol}>{user.posicion}</p>
                    </div>
                    <div className={style.contSeguidores}>
                        <div className={style.seguidores}>
                            <h4 className={style.subT}>SEGUIDORES</h4>
                            <p className={style.num}>{seguidores.length}</p>
                        </div>
                        <div className={style.seguidos}>
                            <h4 className={style.subT}>SEGUIDOS</h4>
                            <p className={style.num}>{seguidos.length}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.contInfo}>
                <h2 className={style.title}>INFORMACIÓN SOBRE MI</h2>
                <h4 className={style.subTitle}>Sobre mi</h4>
                <p className={style.sobreTxt}>{user.experiencia || "Sin datos"}</p>
                <h4 className={style.subTitle}>Tiempo de experiencia</h4>
                <p className={style.sobreTxt}>{user.expTime + " años" || "Sin datos"}</p>
                {/* <ul>
                    <li className={style.trabajo}>Reclutador para el Inter Miami CF (2022-Actualidad) </li>
                    <li className={style.trabajo}>Reclutador para fuerzas básicas de Chivas USA (2005 - 2014) </li>
                </ul> */}
            </div>
            {!isVisitorBool && <div className={style.contBuscar}>
                <h2 className={style.title}>¿EN BUSCA DE JUGADORES?</h2>
                <p className={style.buscarTxt}>Con nuestro buscador podrás encontrar los mejores talentos según características como edad, ubicación, habilidades y más filtros.</p>
                <button className={style.btnBuscar} onClick={inAmigos}>Probar ahora</button>
            </div>}
        </div>
    )
}

export default Otro