import style from "./Seleccion.module.css"
import { useState } from "react"
import { useNavigate } from 'react-router-dom'
import logo from "../../assets/Logo.svg"
import { habilidades } from "../funciones"

export default function Habilidades() {
    const [habilidadesSeleccionadas, setHabilidadesSeleccionadas] = useState([]);
    const [aviso, setAviso] = useState("")

    const usuario = JSON.parse(localStorage.getItem("dataUser"))

    const navigate = useNavigate();

    const onChanceInputs = (habilidad) => {
        const index = habilidadesSeleccionadas.indexOf(habilidad);
        if (index === -1) {
            setHabilidadesSeleccionadas([...habilidadesSeleccionadas, habilidad]);
        } else {
            const nuevasHabilidades = [...habilidadesSeleccionadas];
            nuevasHabilidades.splice(index, 1);
            setHabilidadesSeleccionadas(nuevasHabilidades);
        }
    }

    const continuar = () => {
        if (habilidadesSeleccionadas.length === 0) {
            setAviso("Debes seleccionar al menos una habilidad para continuar")
            return
        }
        usuario.habilidadesSeleccionadas = habilidadesSeleccionadas
        localStorage.setItem("dataUser", JSON.stringify(usuario))
        navigate("/metricas")
    }

    const regresar = () => {
        navigate("/extra");
    }

    const onClickHome = () => {
        navigate("/")
    }

    return (
        <div className={style.container}>
            <div className={style.general}>
                <div className={style.contLogo}>
                    <img className={style.logo} src={logo} alt="Big Sport Data" />
                </div>
                <div className={style.habilidades}>
                    <div className={style.inter}>
                        <h1 className={style.regName}>HABILIDADES DESTACADAS</h1>
                        <div className={style.conText}>
                            <span className={style.linkText}>
                                De la siguiente lista de habilidades selecciona aquellas en las que consideres que destaques más en tu juego.
                            </span>
                        </div>
                        <div className={style.contOpciones}>
                            <div className={style.opciones}>
                                {habilidades.map((habilidad, index) => (
                                    <div key={index} className={style.opcionh}>
                                        <div className={style.contCheck}>
                                        <label className={style.labelCheckbox}>
                                            <input
                                                type="checkbox"
                                                className={style.checkbox}
                                                onChange={() => onChanceInputs(habilidad.nombre)}
                                                checked={habilidadesSeleccionadas.includes(habilidad.nombre)}
                                            />
                                            
                                                <span className={style.textCheck}>
                                                    {habilidad.nombre}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <span className={style.avisoB}>{aviso}</span>
                        <div className={style.contB}>
                            <button
                                className={style.btnRegresar}
                                onClick={() => regresar()}
                            >
                                Regresar
                            </button>
                            <button
                                className={style.btnContinuar}
                                onClick={continuar}
                            >
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
                <p className={style.copyright}>© 2024. Big Sport Data Todos los derechos reservados.</p>
            </div>
        </div>
    )
}