import { useEffect, useState } from 'react'
import styles from "./Visitor.module.css"
import { getVisitorPublicaciones, getVisitorUser } from '../../actions'
import { useDispatch, useSelector } from 'react-redux'
import ModalMetrica from "../ModalMetrica/ModalMetrica.jsx";
import StadisticsVisitor from '../Stadistics/StadisticsVisitor.jsx'
import News from '../News/News'
import { useParams } from 'react-router'
import ModalNotificaciones from "../ModalNotificaciones/ModalNotificaciones.jsx"
import UserVisitor from '../User/UserVisitor.jsx'
import FeedVisitor from '../Feed/FeedVisitor.jsx'
import Otro from '../Otro/Otro.jsx'
import NavSecond from '../NavSecond/NavSecond.jsx'
import ModalUser from '../ModalUser/ModalUser.jsx'
import ModalMetricaVisitor from '../ModalMetrica/ModalMetricaVisitor.jsx'
import axios from 'axios'
import { URL } from '../../link'

const Visitor = ({ inVisitor, notificacionesBool, clickMessage , desactivarNotificacion, userBool, logOut, inEdit, noticias, publicacionView, seguir }) => {
  const params = useParams()
  const id = params.id
  const dispatch = useDispatch()
  const detailUser = useSelector(state => state.dataVisitorUser)
  const publicaciones = useSelector(state => state.visitorPublicaciones)
  const [entrenamientos, setEntrenamientos] = useState([])
  const userPubliaciones = []
  const [metricaBool, setMetricaBool] = useState(false)
  const [metricaValor, setMetricaValor] = useState("")

  const [isVisitorBool, setIsVisitorBool] = useState(true)

  const [bool, setBool] = useState(false)

  const metricaClick = (metricaV) => {
    setMetricaBool(true)
    setMetricaValor(metricaV)
  }
  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (notificacionesBool && event.target.closest('.modal-notificaciones') === null || userBool && event.target.closest('.modal-user') === null) {
        desactivarNotificacion();
      }
    };
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [notificacionesBool, desactivarNotificacion, userBool]);

  const getEntrenamientos = async () => {
    try {
      const response = await axios.get(`${URL}/entrenamiento/user/${id}`)
      setEntrenamientos(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    dispatch(getVisitorUser(id))
    dispatch(getVisitorPublicaciones(id))
    getEntrenamientos()
  }, [dispatch, id])

  const clickBool = () => {
    setBool(!bool)
  }


  return (
    <div className={styles.container}>
      <div className={bool ? styles.partDesactive : styles.partOne}>
        {detailUser.rol === "Jugador" ? 
          <StadisticsVisitor detailUser={detailUser} metricaClick={metricaClick} entrenamientos={entrenamientos}/> 
        : 
          <Otro user={detailUser} isVisitorBool={isVisitorBool}/>}
      </div>

      <div className={bool ? styles.partDesactive : styles.partTwo}>
       {detailUser.rol === "Jugador" &&
          <UserVisitor user={detailUser}/>}
      </div>

      <div className={bool ? styles.partThre : styles.partDesactive}>
        <FeedVisitor user={detailUser} publicaciones={publicaciones} userPubliaciones={userPubliaciones} inVisitor={inVisitor} noticias={noticias} publicacionView={publicacionView} seguir={seguir}/>
      </div>
            
      {notificacionesBool && <ModalNotificaciones clickMessage={clickMessage} />}
      {userBool === true && <ModalUser logOut={logOut} inEdit={inEdit} />}
    </div>
  )
}

export default Visitor