import { useState, useEffect } from 'react';
import { URL } from "../../link.js"
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from "./Forulario.module.css"
import axios from 'axios';
import userIcon from "../../assets/user.svg"
import { getUser } from '../../actions';
import { validarStrings, validarAltura, validarPeso } from './validacion';
import { mesesOptions, estadosOptions } from '../funciones';
import ModalUser from '../ModalUser/ModalUser.jsx';
import pdf from "../../assets/pdf.png";
import checkNo from "../../assets/checkNo.png"
import checkYes from "../../assets/checkYes.png"
import ModalEliminar from '../ModalEliminar/ModalEliminar.jsx';
import municipiosByEstado from "../municipios.json"
import ModalImage from '../ModalImagen/ModalImage.jsx';
import ModalNotificaciones from '../ModalNotificaciones/ModalNotificaciones.jsx';

const Formulario = ({desactivarNotificacion, usuario, userBool, logOut, inEdit, checkNotificaciones,clickMessage, notificacionesBool}) => {
    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [foto, setFoto] = useState(usuario?.foto);
    const [perfilBool, setPerfilBool] = useState(true);
    const [laboralBool, setLaboralBool] = useState(false);
    const [cuentaBool, setCuentaBool] = useState(false);
    const [cvUpLoad, setCvUpLoad] = useState("");
    const [cvName, setCvName] = useState("");
    const [checkComunidad, setCheckComunidad] = useState(usuario.notiComunidad)
    const [checkMensajes, setCheckMensajes] = useState(usuario.notiMensajes)
    const [eliminarBool, setEliminarBool] = useState(false)
    const [municipios, setMunicipios] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [urlNewFoto, setUrlNewFoto] = useState("")

    const [isAndroid, setIsAndroid] = useState(false)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const preset_key = "bqgn8ppn";
    const cloud_name = "dvwtgluwc";
    const cloudinaryUrl = `https://api.cloudinary.com/v1_1/dvwtgluwc/image/upload`;

    const upLoadImage = async (e) => {
        const file = e.target.files[0];
        const data = new FormData();
        data.append("file", file);
        data.append("upload_preset", preset_key);
        try {
            const response = await axios.post(cloudinaryUrl, data);
            setFoto(response.data.secure_url);
            const { name } = e.target;
            setFormData((prevData) => ({
                ...prevData,
                [name]: response.data.secure_url,
            }));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const handleDocumentClick = (event) => {
          if (notificacionesBool && event.target.closest('.modal-notificaciones') === null || userBool && event.target.closest('.modal-user') === null) {
            desactivarNotificacion();
          }
        };
        document.addEventListener('click', handleDocumentClick);
        return () => {
          document.removeEventListener('click', handleDocumentClick);
        };
      }, [notificacionesBool, desactivarNotificacion, userBool]);

    const upLoadCv = async (e) => {
        const file = e.target.files[0];
        const data = new FormData();
        data.append("myFile", file);
        try {
            const response = await axios.post(`${URL}/upload/new`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response);
            const { name } = e.target;
            setCvName(response.data.file.originalname);
            setCvUpLoad(response.data.fileUrl);
            setFormData((prevData) => ({
                ...prevData,
                [name]: response.data.fileUrl,
            }));
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setFormErrors((prevData) => updateFormErrors(name, value, prevData));
    };

    const updateFormErrors = (name, value, currentErrors) => {
        const updatedErrors = { ...currentErrors };
        switch (name) {
            case "nombre":
                if (!validarStrings(value)) {
                    updatedErrors[name] = "Invalido";
                } else {
                    delete updatedErrors[name];
                }
                break;
            case "altura":
                if (!validarAltura(value)) {
                    updatedErrors[name] = "Invalido";
                } else {
                    delete updatedErrors[name];
                }
                break;
            case "peso":
                if (!validarPeso(value)) {
                    updatedErrors[name] = "Invalido";
                } else {
                    delete updatedErrors[name];
                }
                break;
            default:
                break;
        }
        return updatedErrors;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if(urlNewFoto !== ""){
            try {
                await axios.put(`${URL}/cloud/deleteImage`, {public_id: usuario.foto})
                formData.foto = urlNewFoto
            } catch (error) {
                console.log(error);
            }
        }
        try {
            const response = await axios.put(`${URL}/usuario/editar/${usuario.id}`, formData);
            localStorage.setItem("dataUser", JSON.stringify(response.data));
            dispatch(getUser(usuario.id));
            navigate("/home");
        } catch (error) {
            console.log(error);
        }
    };

    const handlerEliminarCuenta = async () => {
        try {
            await axios.put(`${URL}/usuario/editar/${usuario.id}`, {
                deleteCuenta: true
            });
            setEliminarBool(false)
            navigate("inicio")
        } catch (error) {
            console.log(error);
        }
    }

    const handlerNotificaciones = async (tipo) => {
        if (tipo === "comunidad") {
            try {
                const notiBool = usuario.notiComunidad
                const response = await axios.put(`${URL}/usuario/editar/${usuario.id}`, {
                    notiComunidad: !notiBool
                })
                localStorage.setItem("dataUser", JSON.stringify(response.data));
                setCheckComunidad(!notiBool)
            } catch (error) {
                console.log(error);
            }
        }
        if (tipo === "mensajes") {
            try {
                const notiBool = usuario.notiMensajes
                const response = await axios.put(`${URL}/usuario/editar/${usuario.id}`, {
                    notiMensajes: !notiBool
                })
                localStorage.setItem("dataUser", JSON.stringify(response.data));
                setCheckMensajes(!notiBool)
            } catch (error) {
                console.log(error);
            }
        }
    }

    const openPdf = () => {
        const url = cvUpLoad;
        window.open(url, '_blank');
    };

    const openPdfActual = () => {
        const url = usuario.cv;
        window.open(url, '_blank');
    }

    const diasOptions = [];
    for (let i = 1; i <= 31; i++) {
        diasOptions.push(<option key={i} value={i}>{i}</option>);
    }

    const currentYear = new Date().getFullYear();
    const yearsOptions = [];
    for (let i = currentYear; i >= 1970; i--) {
        yearsOptions.push(<option key={i} value={i}>{i}</option>);
    }

    const elimnarBoolChange = () => {
        setEliminarBool(!eliminarBool)
    }

    const cambiarContrasena = () => {
        navigate("/actualiza")
    }

    const changeMenu = (select) => {
        if (select === "perfil") {
            setPerfilBool(true);
            setLaboralBool(false);
            setCuentaBool(false);
        }
        if (select === "laboral") {
            setPerfilBool(false);
            setLaboralBool(true);
            setCuentaBool(false);
        }
        if (select === "cuenta") {
            setPerfilBool(false);
            setLaboralBool(false);
            setCuentaBool(true);
        }
    };

    const getMunicipios = (estado) => {
        if (estado) {
            const muni = municipiosByEstado[estado]
            const neuvosMuni = []
            for (let i = 0; i < muni.length; i++) {
                neuvosMuni.push(<option key={muni[i]} value={muni[i]}> {muni[i]} </option>)
            }
            setMunicipios(neuvosMuni)
        }
    }

    useEffect(() => {
        getMunicipios(formData.origen)
    }, [formData.origen])

    useEffect(() => {
        if (window.AndroidInterface) {
            setIsAndroid(true)
        }
    }, [])

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>CONFIGURACIÓN DE LA CUENTA</h2>
            <div className={usuario.rol === "Jugador" ? styles.menu : styles.menuOtro}>
                <div className={styles.btnDiv}>
                    <p className={perfilBool ? styles.btnTtxAct : styles.btnTtx} onClick={() => changeMenu("perfil")}>PERFIL</p>
                </div>
                {usuario.rol === "Otro" && <div className={usuario.rol === "Otro" ? styles.btnDiv : styles.btnDivDesc}>
                    <p className={laboralBool ? styles.btnTtxAct : styles.btnTtx} onClick={() => changeMenu("laboral")}>PERFIL LABORAL</p>
                </div>}
                <div className={styles.btnDiv}>
                    <p className={cuentaBool ? styles.btnTtxAct : styles.btnTtx} onClick={() => changeMenu("cuenta")}>CUENTA</p>
                </div>
            </div>
            {perfilBool && <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.part}>
                    <div className={styles.subPart}>
                        <label className={styles.camp}>
                            <div className={styles.contUpLoad}>
                                <p className={styles.descripcion}>Fotografía de perfil</p>
                                <div className={styles.contFoto}>
                                    <img className={styles.foto} src={foto || userIcon} alt="" />
                                    <label className={styles.inputext} onClick={() => setIsModalOpen(!isModalOpen)}>
                                        Reemplazar imagen
                                        {/* <input
                                            type="file"
                                            name="foto"
                                            id="fileInput"
                                            onChange={upLoadImage}
                                            placeholder="N/A"
                                            className={styles.inputext}
                                            accept="image/*"
                                            style={{ display: "none" }}
                                        /> */}
                                    </label>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div className={styles.nacimiento}>
                        <p className={styles.descripcion}>Fecha de nacimiento</p>
                        <div className={styles.contSelect}>
                            <select className={styles.selector} name='dia' onChange={handleChange}>
                                {diasOptions}
                            </select>
                            <select className={styles.selectorM} name='mes' onChange={handleChange}>
                                {mesesOptions}
                            </select>
                            <select className={styles.selector} name='anio' onChange={handleChange}>
                                {yearsOptions}
                            </select>
                        </div>
                    </div>
                </div>
                <div className={styles.part}>
                    {usuario.rol === "Jugador" && <div className={styles.subPart}>
                        <label className={styles.camp}>
                            <p className={styles.descripcion}>Altura</p>
                            <div className={styles.contInput}> <input className={styles.inp} placeholder={usuario.altura || "Sin datos"} type="text" name="altura" value={formData.altura} onChange={handleChange} /> <span className={styles.unidad}>cm</span></div>
                            <p className={styles.danger}>{formErrors.altura}</p>
                        </label>
                        <label className={styles.camp}>
                            <p className={styles.descripcion}>Peso</p>
                            <div className={styles.contInput}><input className={styles.inp} placeholder={usuario.peso || "Sin datos"} type="text" name="peso" value={formData.peso} onChange={handleChange} /> <span className={styles.unidad}>kg</span></div>
                            <p className={styles.danger}>{formErrors.peso}</p>
                        </label>
                    </div>}
                    {usuario.rol === "Jugador" && <div className={styles.contPie}>
                        <p className={styles.descripcion}>Pie favorito</p>
                        <div className={styles.contOpcionesPie}>
                            <input
                                type="radio"
                                className={styles.checkboxP}
                                id="Derecho"
                                value="Derecho"
                                checked={formData.pie === "Derecho"}
                                name='pie'
                                onChange={handleChange}
                            />
                            <label className={styles.labelP} htmlFor="Derecho">Derecho</label>
                            <input
                                type="radio"
                                className={styles.checkboxP}
                                id="Izquierdo"
                                value="Izquierdo"
                                checked={formData.pie === "Izquierdo"}
                                name='pie'
                                onChange={handleChange}
                            />
                            <label className={styles.labelP} htmlFor="Izquierdo">Izquierdo</label>
                            <input
                                type="radio"
                                className={styles.checkboxP}
                                id="Ambos"
                                value="Ambos"
                                checked={formData.pie === "Ambos"}
                                name='pie'
                                onChange={handleChange}
                            />
                            <label className={styles.labelP} htmlFor="Ambos">Ambos</label>
                        </div>
                    </div>}
                    <div className={styles.cont}>
                        <div className={styles.camp}>
                            <p className={styles.descripcion}>Estado</p>
                            <div className={styles.contInp}>
                                <select className={styles.selectCamp} name='origen' onChange={handleChange}>
                                    {estadosOptions}
                                </select>
                            </div>
                        </div>
                        <div className={styles.camp}>
                            <p className={styles.descripcion}>Ciudad</p>
                            <div className={styles.contInp}>
                                <select className={styles.selectCamp} name='direccion' onChange={handleChange}> 
                                    {municipios}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className={styles.conto}>
                            <div className={styles.camp}>
                                <p className={styles.descripcionCamp}>Equipo actual</p>
                                <div className={styles.contInp}>
                                    <input className={styles.inputCamp} name='fondo' type="text" value={formData.fondo} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                </div>
                {Object.keys(formErrors).length > 0 ? <h4 className={styles.nota}>Revisa los errores antes de guardar</h4> : <button className={styles.btn} onClick={handleSubmit}>Guardar cambios</button>}
            </form>}
            {laboralBool && <form className={styles.form}>
                <div className={styles.part}>
                    <div className={styles.historial}>
                        <h2 className={styles.descripcion}>Historial laboral</h2>
                        <textarea
                            className={styles.experiencia}
                            name="experiencia"
                            id="experiencia"
                            maxLength={255}
                            onChange={handleChange}
                            value={formData.experiencia}
                        ></textarea>
                        <h4 className={styles.descripcion}>{formData.experiencia && formData.experiencia.length}/255</h4>
                    </div>
                    <div className={styles.exptime}>
                        <label className={styles.camp}>
                            <p className={styles.descripcion}>Tiempo de experiencia</p>
                            <div className={styles.contInput}> <input className={styles.inp} placeholder={usuario.expTime || "Sin datos"} type="text" name="expTime" value={formData.expTime} onChange={handleChange} /> <span className={styles.unidad}>años</span></div>
                        </label>
                    </div>
                    <div className={styles.cv}>
                        <label className={styles.camp}>
                            <p className={styles.descripcion}>Subir CV - 5mb</p>
                            <div className={styles.contInput}>
                                {cvName === "" ? <label className={styles.subirPdf}>
                                    <input className={styles.inp}
                                        type="file"
                                        name="cv"
                                        id="cv"
                                        style={{ display: "none" }}
                                        accept=".pdf"
                                        onChange={upLoadCv} />
                                </label> : <div className={styles.descarga}>
                                    <img className={styles.pdf} src={pdf} alt="" />
                                    <p className={styles.descripcion}>{cvName}</p>
                                </div>}
                                {usuario.cv !== null && cvName === "" ? (
                                    <p className={styles.btnVer} onClick={openPdfActual}>Ver actual</p>
                                ) : cvName !== "" ? (
                                    <p className={styles.btnVer} onClick={openPdf}>Vista previa</p>
                                ) : null}

                            </div>
                        </label>
                    </div>
                </div>
                {Object.keys(formErrors).length > 0 ? <h4 className={styles.nota}>Revisa los errores antes de guardar</h4> : <button className={styles.btn} onClick={handleSubmit}>Guardar cambios</button>}
            </form>}
            {cuentaBool && <div className={styles.contCuenta}>
                <h2 className={styles.descripcionB}>NOTIFICACIONES DE CORREO ELECTRÓNICO</h2>
                <div className={styles.seccion}>
                    <h4 className={styles.sub}>Notificaciones de comunidad (Nuevos seguidores)</h4>
                    <img className={styles.check} src={checkComunidad ? checkYes : checkNo} alt="" onClick={() => handlerNotificaciones("comunidad")} />
                </div>
                <div className={styles.seccion}>
                    <h4 className={styles.sub}>Notificaciones de mensajes</h4>
                    <img className={styles.check} src={checkMensajes ? checkYes : checkNo} alt="" onClick={() => checkNotificaciones()} />
                </div>
                <h2 className={styles.descripcionB}>CUENTA</h2>
                <div className={styles.seccion}>
                    <h4 className={styles.sub} >Cambiar contraseña</h4>
                    <button className={styles.btnC} onClick={cambiarContrasena}>Cambiar</button>
                </div>
                <div className={styles.seccion}>
                    <h4 className={styles.sub}>Eliminar cuenta permanentemente</h4>
                    <button className={styles.btnD} onClick={elimnarBoolChange}>Eliminar</button>
                </div>
            </div>}
            {userBool === true && <ModalUser logOut={logOut} inEdit={inEdit} />}
            {eliminarBool && <ModalEliminar elimnarBoolChange={elimnarBoolChange} handlerEliminarCuenta={handlerEliminarCuenta} />}
           {isModalOpen === true && <ModalImage setIsModalOpen={setIsModalOpen} user={usuario} setUrlNewFoto={setUrlNewFoto}/>}
            
            {notificacionesBool && <ModalNotificaciones clickMessage={clickMessage} />}
            {userBool === true && <ModalUser logOut={logOut} inEdit={inEdit} />}
            
        </div>
    );
};

export default Formulario;
