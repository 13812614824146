import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';

const calcularEdad = (dia, mes, anio) => {
    const fechaNacimiento = new Date(anio, mes - 1, dia);
    const fechaActual = new Date();
    
    let edad = fechaActual.getFullYear() - fechaNacimiento.getFullYear();
    
    const mesActual = fechaActual.getMonth() + 1;
    const diaActual = fechaActual.getDate();
    
    if (mesActual < mes || (mesActual === mes && diaActual < dia)) {
      edad--;
    }
    
    return edad;
  };

  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
    
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  const formFecha = (fecha) => {
    return formatDistanceToNow(new Date(fecha), { addSuffix: true, locale: es })
}

const conteLikeByValor = (valor, publicacion) => {
    return publicacion.likes.filter(like => like.valor === valor).length
}

const filtrarUsuarios = (usuarios, { posiciones, habilidades, alturaMin, alturaMax, edadMin, edadMax, pie, scoreMin, scoreMax }) => {
    return usuarios.filter(usuario => {
        if (posiciones && posiciones.length > 0) {
            if (!posiciones.includes(usuario.posicion)) {
                return false;
            }
        }
        if (habilidades && habilidades.length > 0) {
            if (!usuario.habilidadesSeleccionadas || !usuario.habilidadesSeleccionadas.some(habilidad => habilidades.includes(habilidad))) {
                return false;
            }
        }
        if (alturaMin !== undefined && alturaMax !== undefined) {
            if (usuario.altura < alturaMin || usuario.altura > alturaMax) {
                return false;
            }
        }
        if (edadMin !== undefined && edadMax !== undefined) {
            const edad = calcularEdad(usuario.dia, usuario.mes, usuario.anio);
            if (edad < edadMin || edad > edadMax) {
                return false;
            }
        }
        if (pie && usuario.pie !== pie) {
            return false;
        }
        // Filtro por score
        if (scoreMin !== undefined && usuario.score < scoreMin) {
            return false;
        }
        if (scoreMax !== undefined && usuario.score > scoreMax) {
            return false;
        }

        return true;
    });
};


const buscarPorNombre = (usuarios, nombre) => {
    return usuarios.filter(usuario => {
      const nombreUsuario = usuario.nombre.toLowerCase();
      const nombreBusqueda = nombre.toLowerCase();
      return nombreUsuario.includes(nombreBusqueda);
    });
  };

  const meSigue = (usuarios, userId) => {
    if(usuarios.some(user => user.id === userId)){
        return true
    } else {
        return false
    }
  } 
  
  const loSigo = (usuarios, userId) => {
    if(usuarios.some(user => user.id === userId)){
      return true  
    } else {
        return false
    }
  }

  const filterMetricas = (detailUser, metricaV) => {
    return detailUser.Metricas.filter(metric => metric.nombre === metricaV)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
}

const formatoLegible = (cadena) => {
    if(cadena && cadena.length > 0){
        return cadena.replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase());
    }
};

const notificacionesNoLeidas = (arrayNotificaciones) => {
    const notificacionesNoLeidas = arrayNotificaciones.filter(notificacion => !notificacion.leido);
    return notificacionesNoLeidas.length;
}

const getUltimoMensaje = (arrayMensajes, idContacto) => {
    // Filtrar mensajes donde el idContacto es DestinatarioId o RemitenteId
    const mensajesFiltrados = arrayMensajes.filter(mensaje => 
        mensaje.DestinatarioId === idContacto || mensaje.RemitenteId === idContacto
    );

    // Ordenar los mensajes filtrados por fecha de creación en orden descendente
    mensajesFiltrados.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    // Retornar el primer mensaje después de ordenar (el más reciente)
    return mensajesFiltrados[0];
}

const mesanjesNoLeidos = (arrayMensajes) => {
    let contador=0;
    for (let i = 0; i < arrayMensajes.length; i++) {
        if (!arrayMensajes[i].leido){contador++}
    }
    return contador;
}

const promedioScore = (arrayJugadores) => {
    console.log(arrayJugadores)
    let promedio = 0;
    for (let i = 0; i < arrayJugadores?.length; i++) {
        promedio += arrayJugadores[i].score;
    }
    // Calcular el promedio y formatear a un solo decimal
    promedio = promedio / arrayJugadores?.length;
    return parseFloat(promedio.toFixed(1)); // Convertir de nuevo a número para evitar una cadena como resultado
}





const posicionOptions = [
    <option key="" value="">Selecciona una posición</option>,
    <option key="Portero" value="Portero">Portero</option>,
    <option key="DefensaCentral" value="DefensaCentral">Defensa Central</option>,
    <option key="DefensaLateralIzquierdo" value="DefensaLateralIzquierdo">Defensa Lateral Izquierdo</option>,
    <option key="DefensaLateralDerecho" value="DefensaLateralDerecho">Defensa Lateral Derecho</option>,
    <option key="MediocentroDefensivo" value="MediocentroDefensivo">Mediocentro Defensivo</option>,
    <option key="Mediocentro" value="Mediocentro">Mediocentro</option>,
    <option key="MediocentroOfensivo" value="MediocentroOfensivo">Mediocentro Ofensivo</option>,
    <option key="InteriorIzquierdo" value="InteriorIzquierdo">Interior Izquierdo</option>,
    <option key="InteriorDerecho" value="InteriorDerecho">Interior Derecho</option>,
    <option key="ExtremoIzquierdo" value="ExtremoIzquierdo">Extremo Izquierdo</option>,
    <option key="ExtremoDerecho" value="ExtremoDerecho">Extremo Derecho</option>,
    <option key="DelanteroCentro" value="DelanteroCentro">Delantero Centro</option>,
  ];

  const habilidadesOptions = [
    <option key="" value="">Selecciona una habilidad</option>,
    <option key="Aceleración" value="Aceleración">Aceleración</option>,
    <option key="Velocidad máxima" value="Velocidad máxima">Velocidad máxima</option>,
    <option key="Definición" value="Definición">Definición</option>,
    <option key="Potencia de tiro" value="Potencia de tiro">Potencia de tiro</option>,
    <option key="Tiro lejano" value="Tiro lejano">Tiro lejano</option>,
    <option key="Penales" value="Penales">Penales</option>,
    <option key="Cabezasos" value="Cabezasos">Cabezasos</option>,
    <option key="Distribución de balón" value="Distribución de balón">Distribución de balón</option>,
    <option key="Tiros de esquina" value="Tiros de esquina">Tiros de esquina</option>,
    <option key="Regate" value="Regate">Regate</option>,
    <option key="Control de balón" value="Control de balón">Control de balón</option>,
    <option key="Marcaje" value="Marcaje">Marcaje</option>,
    <option key="Entrada limpia" value="Entrada limpia">Entrada limpia</option>,
    <option key="Barrida" value="Barrida">Barrida</option>,
    <option key="Centros" value="Centros">Centros</option>,
    <option key="Pase corto" value="Pase corto">Pase corto</option>,
    <option key="Pase largo" value="Pase largo">Pase largo</option>,
  ];

  const mesesOptions = [
    <option key="0" value="0">Mes</option>,
    <option key="enero" value="1">Enero</option>,
    <option key="febrero" value="2">Febrero</option>,
    <option key="marzo" value="3">Marzo</option>,
    <option key="abril" value="4">Abril</option>,
    <option key="mayo" value="5">Mayo</option>,
    <option key="junio" value="6">Junio</option>,
    <option key="julio" value="7">Julio</option>,
    <option key="agosto" value="8">Agosto</option>,
    <option key="septiembre" value="9">Septiembre</option>,
    <option key="octubre" value="10">Octubre</option>,
    <option key="noviembre" value="11">Noviembre</option>,
    <option key="diciembre" value="12">Diciembre</option>
];

const estadosOptions = [
    <option key="0" value="0">Selecciona un estado</option>,
    <option key="CDMX" value="CDMX">Ciudad de México</option>,
    <option key="AGS" value="AGS">Aguascalientes</option>,
    <option key="BC" value="BC">Baja California</option>,
    <option key="BCS" value="BCS">Baja California Sur</option>,
    <option key="CAMP" value="CAMP">Campeche</option>,
    <option key="CHIS" value="CHIS">Chiapas</option>,
    <option key="CHIH" value="CHIH">Chihuahua</option>,
    <option key="COAH" value="COAH">Coahuila</option>,
    <option key="COL" value="COL">Colima</option>,
    <option key="DGO" value="DGO">Durango</option>,
    <option key="GTO" value="GTO">Guanajuato</option>,
    <option key="GRO" value="GRO">Guerrero</option>,
    <option key="HGO" value="HGO">Hidalgo</option>,
    <option key="JAL" value="JAL">Jalisco</option>,
    <option key="MEX" value="MEX">Estado de México</option>,
    <option key="MICH" value="MICH">Michoacán</option>,
    <option key="MOR" value="MOR">Morelos</option>,
    <option key="NAY" value="NAY">Nayarit</option>,
    <option key="NL" value="NL">Nuevo León</option>,
    <option key="OAX" value="OAX">Oaxaca</option>,
    <option key="PUE" value="PUE">Puebla</option>,
    <option key="QRO" value="QRO">Querétaro</option>,
    <option key="ROO" value="ROO">Quintana Roo</option>,
    <option key="SLP" value="SLP">San Luis Potosí</option>,
    <option key="SIN" value="SIN">Sinaloa</option>,
    <option key="SON" value="SON">Sonora</option>,
    <option key="TAB" value="TAB">Tabasco</option>,
    <option key="TAM" value="TAM">Tamaulipas</option>,
    <option key="TLAX" value="TLAX">Tlaxcala</option>,
    <option key="VER" value="VER">Veracruz</option>,
    <option key="YUC" value="YUC">Yucatán</option>,
    <option key="ZAC" value="ZAC">Zacatecas</option>
];

const opcionesPosiciones = [
    "Portero", "DefensaCentral", "DefensaLateralIzquierdo", "DefensaLateralDerecho",
    "MediocentroDefensivo", "Mediocentro", "MediocentroOfensivo", "InteriorIzquierdo",
    "InteriorDerecho", "ExtremoIzquierdo", "ExtremoDerecho", "DelanteroCentro"
];

const habilidades = [
    { nombre: "Aceleración" },
    { nombre: "Velocidad máxima" },
    { nombre: "Definición" },
    { nombre: "Potencia de tiro" },
    { nombre: "Tiro lejano" },
    { nombre: "Penales" },
    { nombre: "Cabezasos" },
    { nombre: "Distribución de balón" },
    { nombre: "Tiros de esquina" },
    { nombre: "Regate" },
    { nombre: "Control de balón" },
    { nombre: "Marcaje" },
    { nombre: "Entrada limpia" },
    { nombre: "Barrida" },
    { nombre: "Centros" },
    { nombre: "Pase corto" },
    { nombre: "Pase largo" }
];




  export {calcularEdad, urlBase64ToUint8Array, formFecha, conteLikeByValor, filtrarUsuarios, buscarPorNombre, meSigue, filterMetricas, formatoLegible, notificacionesNoLeidas, getUltimoMensaje, mesanjesNoLeidos, promedioScore, loSigo, posicionOptions, habilidadesOptions, mesesOptions, estadosOptions, opcionesPosiciones, habilidades}