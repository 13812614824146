import axios from "axios";
import {URL} from "../link.js";

export const GET_USER = "GET_USER"
export const GET_DETAIL_USER = "GET_DETAIL_USER"
export const GET_ALL_PUBLICACIONES = "GET_ALL_PUBLICACIONES"
export const DELETE_PUBLICACION = "DELETE_PUBLICACION"
export const GET_USER_PUBLICACIONES = "GET_USER_PUBLICACIONES"
export const GET_VISITOR_PUBLICACIONES = "GET_VISITOR_PUBLICACIONES"
export const GET_VISITOR_USER = "GET_VISITOR_USER"
export const GET_AMIGOS = "GET_AMIGOS"
export const GET_MENSAJES_NUM = "GET_MENSAJES_NUM"
export const MAS_UN_MENSAJE = "MAS_UN_MENSAJE"
export const MENSAJES_LEIDOS = "MENSAJES_LEIDOS"
export const GET_ALL_USUARIOS = "GET_ALL_USUARIOS"
export const GET_SOLICITUDES_RECIBIDAS = "GET_SOLICITUDES_RECIBIDAS"
export const GET_SOLICITUDES_ENVIADAS = "GET_SOLICITUDES_ENVIADAS"
export const GET_RECOMENDADOS = "GET_RECOMENDADOS"
export const GET_MENSAJES = "GET_MENSAJES"
export const GET_NOTIFICACIONES = "GET_NOTIFICACIONES"
export const DELETE_NOTIFICACION = "DELETE_NOTIFICACION"
export const GET_ALL_JUGADORES = "GET_ALL_JUGADORES"
export const GET_ALL_RECLUTADORES = "GET_ALL_RECLUTADORES"
export const GET_JUGADORES_LINE = "GET_JUGADORES_LINE"
export const GET_RECLUTADORES_LINE = "GET_RECLUTADORES_LINE"
export const GET_USUARIOS_DESACTIVOS = "GET_USUARIOS_DESACTIVOS"
export const GET_USUARIOS_ACTIVOS = "GET_USUARIOS_ACTIVOS"
export const UPDATE_NUEVO_USUARIO = "UPDATE_NUEVO_USUARIO"
export const GET_SEGUIDORES = "GET_SEGUIDORES"
export const GET_SEGUIDOS = "GET_SEGUIDOS"
export const GET_LISTAS = "GET_LISTAS"
export const CREAR_LISTA = "CREAR_LISTA"
export const GET_NOTICIAS = "GET_NOTICIAS"
export const GET_ENTRENAMIENTOS = "GET_ENTRENAMIENTOS"

export function getUser(id){
    return async function(dispatch){
        try {
            const response = await axios.get(`${URL}/usuario/mipefil/${id}`)
            localStorage.setItem("dataUser", JSON.stringify(response.data))
            return dispatch({
                type: GET_USER,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getVisitorUser(id){
    return async function(dispatch) {
        try {
            const response = await axios.get(`${URL}/usuario/mipefil/${id}`)
            return dispatch({
                type: GET_VISITOR_USER,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getDetailUser(payload){
    return {
        type: GET_DETAIL_USER,
        payload: payload
    }
}

export function getAllPublicaciones(id){
    return async function (dispatch) {
       try {
        const response = await axios.get(`${URL}/publicacion/all/${id}`)
        return dispatch({
            type: GET_ALL_PUBLICACIONES,
            payload: response.data
        })
       } catch (error) {
        console.log(error)
       }
    }
}

export function deletePublicacion(id){
    return async function (dispatch){
        try {
            
            const response = await axios.delete(`${URL}/publicacion/delete/${id}`)
            return dispatch({
                type: DELETE_PUBLICACION,
                payload: response.data
            })
            
        } catch (error) {
            console.log(error)
        }
    }
}

export function getUserPublicaciones(userId){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/publicacion/historial/${userId}`)
            return dispatch({
                type: GET_USER_PUBLICACIONES,
                payload: response.data.Publicacions
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getVisitorPublicaciones(userId){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/publicacion/historial/${userId}`)
            return dispatch({
                type: GET_VISITOR_PUBLICACIONES,
                payload: response.data.Publicacions
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getAmigos(id){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/usuario/amigos/${id}`)
            return dispatch({
                type: GET_AMIGOS,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getAllUsers(){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/usuario/all`)
            return dispatch({
                type: GET_ALL_USUARIOS,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getMensajesNoLeidos(id){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/mensaje/no-read/${id}`)
            return dispatch({
                type: GET_MENSAJES_NUM,
                payload: response.data.length
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function masunmensaje(){
    return {
        type: MAS_UN_MENSAJE,
        payload: 1
    }
}

export function mensajesLeidos(){
    return {
        type: MENSAJES_LEIDOS,
        payload: 0
    }
}

export function getSolicitudesRecibidas(id){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/solicitud/recibidas/${id}`)
            return dispatch({
                type: GET_SOLICITUDES_RECIBIDAS,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getSolicitudesEnviadas(id){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/solicitud/enviadas/${id}`)
            return dispatch({
                type: GET_SOLICITUDES_ENVIADAS,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getMensajes(id){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/mensaje/historial/${id}`)
            return dispatch({
                type: GET_MENSAJES,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getNotificaciones(id){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/notificacion/user/${id}`)
            return dispatch({
                type: GET_NOTIFICACIONES,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function deleteNotificaciones(id){
    return async function(dispatch) {
        try {
            const response = await axios.put(`${URL}/notificacion/read/${id}`)
            return dispatch({
                type: DELETE_NOTIFICACION,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getAllJugadores(id){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/usuario/jugadores/${id}`)
            return dispatch({
                type: GET_ALL_JUGADORES,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getAllReclutadores(){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/usuario/reclutadores`)
            return dispatch({
                type: GET_ALL_RECLUTADORES,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getJugadoresLine(){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/usuario/usuariosline`)
            return dispatch({
                type: GET_JUGADORES_LINE,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getReclutadoresLine(){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/usuario/reclutadoresline`)
            return dispatch({
                type: GET_RECLUTADORES_LINE,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getUserDesactivos(){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/admin/desactivos`)
            return dispatch({
                type: GET_USUARIOS_DESACTIVOS,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getUserActivos(){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/admin/activos`)
            return dispatch({
                type: GET_USUARIOS_ACTIVOS,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getSeguidores(id){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/usuario/seguidores/${id}`)
            return dispatch({
                type: GET_SEGUIDORES,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getSeguidos(id){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/usuario/seguidos/${id}`)
            return dispatch({
                type: GET_SEGUIDOS,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function dispachSeguir(data){
    return async function(){
        try {
            const response = await axios.post(`${URL}/solicitud/nueva`, data)
            return response.data
        } catch (error) {
            console.log(error);
        }
    }
}

export function dispacherDejarSeguir(data) {
    return async function () {
        try {
            const response = await axios.put(`${URL}/solicitud/cancelar`, data)
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export function editarPerfil(id, user){
    return async function (){
        try {
            const response = await axios.put(`${URL}/usuario/editar/${id}`, user)
            return response.data
        } catch (error) {
            console.log(error);
        }
    }
}

export function dispatchEnviarCorreo(correo){
    return async function(){
        try {
            const res = await axios.post(`${URL}/usuario/recuperar`, { correo });
            return res.data
        } catch (error) {
            return error
        }
    }
}

export function getListas(id){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/lista/creador/${id}`)
            return dispatch({
                type: GET_LISTAS,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function crearLista(data){
    return async function(dispatch){
        try {
            const response = await axios.post(`${URL}/lista/nueva`, data)
            return dispatch({
                type: CREAR_LISTA,
                payload: response.data
            })
        } catch (error) {
            console.log(error);
        }
    }
}

export function getNoticias(){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/noticia/all`)
            return dispatch({
                type: GET_NOTICIAS,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getEntrenamientos(id){
    return async function (dispatch){
        try {
            const response = await axios.get(`${URL}/entrenamiento/user/${id}`
            )
            return dispatch({
                type: GET_ENTRENAMIENTOS,
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}