import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { URL } from '../../link';
import { useDispatch, useSelector } from 'react-redux';
import style from "./PublicacionView.module.css";
import Publicaciones from '../Publiaciones/Publicaciones';
import StadisticsVisitor from '../Stadistics/StadisticsVisitor';
import { getVisitorPublicaciones, getVisitorUser } from '../../actions';
import FeedVisitor from '../Feed/FeedVisitor';
import NavTree from '../NavSecond/NavTree';
import loading from "../../assets/Reload.gif";
import ModalMetricaVisitor from '../ModalMetrica/ModalMetricaVisitor';

const PublicacionView = ({ seguir, inVisitor, noticias, publicacionView }) => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [publicacion, setPublicacion] = useState({});
    const [publiID, setPubliID] = useState(params.id);  // Usamos params.id directamente
    const [user, setUser] = useState();
    const [metricaBool, setMetricaBool] = useState(false)
    const [metricaValor, setMetricaValor] = useState("")


    const detailUser = useSelector(state => state.dataVisitorUser);
    const publicaciones = useSelector(state => state.visitorPublicaciones);
    const userPubliaciones = [];

    const clickHome = () => {
        navigate("/home");
    };

    const back = () => {
        navigate(-1);
    };

    const dataUserLocal = JSON.parse(localStorage.getItem("dataUser"));

    useEffect(() => {
        async function getPublicacion(publiID) {
            const response = await axios.get(`${URL}/publicacion/${publiID}`);
            setPublicacion(response.data);
            
            const responseUser = await axios.get(`${URL}/usuario/mipefil/${response.data.usuarioId}`);
            setUser(responseUser.data);
            dispatch(getVisitorUser(response.data.usuarioId));
            dispatch(getVisitorPublicaciones(response.data.usuarioId));
        }

        getPublicacion(publiID);  // Actualizamos la publicación cada vez que publiID cambia

    }, [publiID, dispatch]);  // Incluimos dispatch en las dependencias

    // Detecta cambios en el ID de los parámetros de la URL
    useEffect(() => {
        setPubliID(params.id);  // Actualiza el ID local cuando cambian los parámetros
    }, [params.id]);

    const metricaClick = (metricaV) => {
        setMetricaBool(true)
        setMetricaValor(metricaV)
      }

    return (
        <div className={style.publicacionView}>
            {Object.keys(publicacion).length > 0 ? (
                <div className={style.contPublicacion}>
                    {user && (
                        <div className={style.contUsuario}>
                            <StadisticsVisitor detailUser={user} metricaClick={metricaClick}/>
                        </div>
                    )}
                    <div className={style.publicacion}>
                        <p className={style.btnTxt} onClick={back}>{"< Regresar"}</p>
                        <Publicaciones
                            key={publicacion.id}
                            id={publicacion.id}
                            tipo={publicacion.tipo}
                            userId={publicacion.Usuario?.id}
                            foto={publicacion.Usuario?.foto}
                            nombre={publicacion.Usuario?.nombre}
                            mensaje={publicacion.mensaje}
                            img={publicacion.img}
                            video={publicacion.video}
                            likes={publicacion.Likes}
                            comentarios={publicacion.Comentarios}
                            compartidos={publicacion.compartidos}
                            fecha={publicacion.updatedAt}
                            origenID={publicacion.origenID}
                            origenName={publicacion.origenName}
                            origenUserID={publicacion.origenUserID}
                            origenMensaje={publicacion.origenMensaje}
                            origenUserFoto={publicacion.origenUserFoto}
                            oldcreatedAt={publicacion.oldcreatedAt}
                            inVisitor={inVisitor}
                            userRol={publicacion.Usuario.rol}
                            posicion={publicacion.Usuario.posicion}
                            dia={publicacion.Usuario.dia}
                            mes={publicacion.Usuario.mes}
                            anio={publicacion.Usuario.anio}
                            aplausos={publicacion.Aplausos}
                            seguir={seguir}
                            publicacionView={publicacionView}
                        />
                    </div>
                    <div className={style.feed}>
                        <FeedVisitor user={detailUser} publicaciones={publicaciones} userPubliaciones={userPubliaciones} inVisitor={inVisitor} noticias={noticias} publicacionView={publicacionView} seguir={seguir} />
                    </div>
                </div>
            ) : (
                <div className={style.contNoDisponible}>
                    <p className={style.btnTxt} onClick={back}>{"< Regresar"}</p>
                    <img src={loading} alt="" />
                </div>
            )}
            {dataUserLocal.id === user?.id ? (
                <div className={style.navSecond}>
                    <NavTree clickHome={clickHome} user={user} isVisitor={false} inVisitor={inVisitor} />
                </div>
            ) : (
                <div className={style.navSecond}>
                    <NavTree clickHome={clickHome} isVisitor={true} user={user} inVisitor={inVisitor} />
                </div>
            )}
            {metricaBool === true && <ModalMetricaVisitor setMetricaBool={setMetricaBool} metricaV={metricaValor} user={detailUser}/>}
        </div>
    );
};

export default PublicacionView;
