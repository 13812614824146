import { useEffect, useState } from 'react';
import style from "./Seleccion.module.css";
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/Logo.svg";
import subir from "../../assets/SubirImagen.png";
import axios from 'axios';
import { mesesOptions, estadosOptions } from '../funciones';
import municipiosByEstado from "../municipios.json"
import ModalImage from '../ModalImagen/ModalImage';
export default function Basica() {
    const [dia, setDia] = useState("");
    const [mes, setMes] = useState("");
    const [anio, setAnio] = useState("");
    const [altura, setAltura] = useState("");
    const [peso, setPeso] = useState("");
    const [origen, setOrigen] = useState("");
    const [direccion, setDireccion] = useState("")
    const [pie, setPie] = useState("")
    const [loading, setLoading] = useState(false);
    const usuario = JSON.parse(localStorage.getItem("dataUser"));
    const [foto, setFoto] = useState("");
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();
    const [aceptaTerminos, setAceptaTerminos] = useState(false);
    const [aviso, setAviso] = useState("")
    const [municipios, setMunicipios] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [urlNewFoto, setUrlNewFoto] = useState("")
    const [equipo, setEquipo] = useState("")

    const preset_key = "bqgn8ppn";
    const cloud_name = "dvwtgluwc";
    const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`;

    const upLoadImage = async (e) => {
        const file = e.target.files[0];
        const data = new FormData();
        data.append("file", file);
        data.append("upload_preset", preset_key);
        setLoading(true);
        try {
            const response = await axios.post(cloudinaryUrl, data);
            setFoto(response.data.secure_url);
            const { name } = e.target;
            setFormData((prevData) => ({
                ...prevData,
                [name]: response.data.secure_url,
            }));
            setLoading(false);
        } catch (error) {
            setAviso("No fue posible  cargar la imagen")
        }
    };

    const continuar = () => {
        if (dia === "" || mes === "" || anio === "" || altura === "" || peso === "" || origen === "" || direccion === "" || !aceptaTerminos || pie === "") {
            setAviso("A un faltan campos por llenar")
        } else {
            usuario.dia = dia
            usuario.mes = mes
            usuario.anio = anio
            usuario.altura = altura
            usuario.peso = peso
            usuario.origen = origen
            usuario.direccion = direccion
            usuario.foto = urlNewFoto
            usuario.pie = pie
            usuario.fondo = equipo
            localStorage.setItem("dataUser", JSON.stringify(usuario))
            navigate("/posicion");
        }
    };

    const regresar = () => {
        navigate("/seleccion");
    }

    const onClickHome = () => {
        navigate("/");
    };

    const diasOptions = [];
    for (let i = 1; i <= 31; i++) {
        diasOptions.push(<option key={i} value={i}>{i}</option>);
    }

    const handleAceptaTerminosChange = (e) => {
        setAceptaTerminos(e.target.checked);
        setAviso("");
    };

    const handlePieChange = (e) => {
        setPie(e.target.value)
        setAviso("");
    }

    const getMunicipios = (estado) => {
        if (estado) {
            setDireccion("selecciona una opción")
            const muni = municipiosByEstado[estado]
            const neuvosMuni = []
            for (let i = 0; i < muni.length; i++) {
                neuvosMuni.push(<option key={muni[i]} value={muni[i]}> {muni[i]} </option>)
            }
            setMunicipios(neuvosMuni)
        }
        setDireccion("selecciona una opción")
    }

    useEffect(() => {
        getMunicipios(origen)
        setDireccion("selecciona una opción")
    }, [origen])


    const currentYear = new Date().getFullYear();
    const yearsOptions = [];
    for (let i = currentYear; i >= 1970; i--) {
        yearsOptions.push(<option key={i} value={i}>{i}</option>);
    }

    return (
        <div className={style.container}>
            <div className={style.general}>
                <div className={style.contLogo}>
                    <img className={style.logo} src={logo} alt="Big Sport Data" />
                </div>
                <div className={style.basica}>
                    <div className={style.inter}>
                        <h1 className={style.regName}>INFORMACIÓN BÁSICA</h1>
                        <div className={style.conText}>
                            <span className={style.linkText}>
                                Estas muy cerca de poder acceder a la plataforma, únicamente requerimos un poco de información adicional sobre tus atributos y cualidades.
                            </span>
                        </div>
                        <div className={style.contFoto}>
                            <p className={style.descripcion}> Agrega tu fotografía o un avatar que te represente</p>
                            <div className={style.contSubida}>
                            {urlNewFoto !== "" ? <img className={style.fotoPerfil} src={urlNewFoto} alt="success" /> : <></>}
                                <button
                                    className={style.uploadButton}
                                    onClick={() => setIsModalOpen(!isModalOpen)}
                                >
                                    {/* <img className={style.foto} src={subir} alt="success" /> */}
                                Subir foto
                                </button>
                                
                                {/* <input
                                    type="file"
                                    id="fileInput"
                                    className={style.fileInput}
                                    onChange={upLoadImage}
                                    accept="image/*"
                                    style={{ display: "none" }}
                                /> */}
                            </div>
                        </div>
                        <div className={style.nacimiento}>
                            <p className={style.descripcion}> Fecha de nacimiento</p>
                            <div className={style.contSelect}>
                                <select className={style.selector} onChange={(e) => setDia(e.target.value)}>
                                    {diasOptions}
                                </select>
                                <select className={style.selectorM} onChange={(e) => setMes(e.target.value)}>
                                    {mesesOptions}
                                </select>
                                <select className={style.selector} onChange={(e) => setAnio(e.target.value)}>
                                    {yearsOptions}
                                </select>
                            </div>
                        </div>
                        <div className={style.contPie}>
                            <p className={style.descripcion}> Pie favorito</p>
                            <div className={style.contOpcionesPie}>
                               <div className={style.opt}>
                               <input
                                    type="radio"
                                    className={style.checkboxP}
                                    id="Derecho"
                                    value="Derecho"
                                    checked={pie === "Derecho"}
                                    onChange={handlePieChange}
                                />
                                <label className={style.labelP} htmlFor="Derecho">Derecho</label>
                               </div>
                                <div className={style.opt}>
                                <input
                                    type="radio"
                                    className={style.checkboxP}
                                    id="Izquierdo"
                                    value="Izquierdo"
                                    checked={pie === "Izquierdo"}
                                    onChange={handlePieChange}
                                />
                                <label className={style.labelP} htmlFor="Izquierdo">Izquierdo</label>
                                </div>
                                <div className={style.opt}>
                                <input
                                    type="radio"
                                    className={style.checkboxP}
                                    id="Ambos"
                                    value="Ambos"
                                    checked={pie === "Ambos"}
                                    onChange={handlePieChange}
                                />
                                <label className={style.labelP} htmlFor="Ambos">Ambos</label>
                                </div>
                            </div>
                        </div>

                        <div className={style.cont}>
                            <div className={style.camp}>
                                <p className={style.descripcionCamp}>Altura</p>
                                <div className={style.contInp}>
                                    <input type="text" className={style.inputCamp} onChange={(e) => setAltura(e.target.value)} />
                                    <span className={style.medida}>cm</span>
                                </div>
                            </div>
                            <div className={style.camp}>
                                <p className={style.descripcionCamp}>Peso</p>
                                <div className={style.contInp}>
                                    <input type="text" className={style.inputCamp} onChange={(e) => setPeso(e.target.value)} />
                                    <span className={style.medida}>kg</span>
                                </div>
                            </div>
                        </div>
                        <div className={style.cont}>
                            <div className={style.camp}>
                                <p className={style.descripcionCamp}>Estado</p>
                                <div className={style.contInp}>
                                    <select className={style.inputCamp} onChange={(e) => setOrigen(e.target.value)}>
                                        {estadosOptions}
                                    </select>
                                </div>
                            </div>
                            <div className={style.camp}>
                                <p className={style.descripcionCamp}>Ciudad</p>
                                <div className={style.contInp}>
                                    <select className={style.inputCamp} onChange={(e) => setDireccion(e.target.value)}>
                                        {municipios}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={style.contCheckb}>
                            <input
                                type="checkbox"
                                className={style.checkbox}
                                id="aceptaTerminos"
                                checked={aceptaTerminos}
                                onChange={handleAceptaTerminosChange}
                            />
                            <label htmlFor="aceptaTerminos" className={style.labelCheckbox}>
                                <span className={style.linkTextCheck}>
                                    Confirmó que tengo el permiso de mis padres para utilizar esta plataforma bajo los
                                </span>
                                <span className={style.linkedCheck}>
                                    términos y condiciones de uso de menores de edad.
                                </span>
                            </label>
                        </div>
                        <div className={style.conto}>
                            <div className={style.camp}>
                                <p className={style.descripcionCamp}>Equipo actual</p>
                                <div className={style.contInp}>
                                    <input className={style.inputCamp} type="text" value={equipo} onChange={(e) => setEquipo(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <span className={style.avisoB}>{aviso}</span>
                        <div className={style.contB}>
                            <button
                                className={style.btnRegresar}
                                onClick={() => regresar()}
                            >
                                Regresar
                            </button>
                            <button
                                className={style.btnContinuar}
                                onClick={continuar}
                                disabled={!aceptaTerminos}
                            >
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
                <p className={style.copyright}>© 2024. Big Sport Data Todos los derechos reservados.</p>
            </div>
            {isModalOpen === true && <ModalImage setIsModalOpen={setIsModalOpen} user={usuario} setUrlNewFoto={setUrlNewFoto}/>}
        </div>
    );
}
