import React from 'react';
import styles from './NavSecond.module.css';
import balonA from "../../assets/balonBtn.png"
import balonB from "../../assets/BalonBtnB.png"
import userA from "../../assets/UserBtnA.png"
import userB from "../../assets/UserBtn.png"

const NavTree = ({ clickHome, user, isVisitor, inVisitor }) => {
  return (
    <div className={styles.navSecond}>
      {isVisitor ?
        <div className={styles.botonesView}>
          <div className={styles.btn} onClick={() => inVisitor(user.id)}>
            <img className={styles.iconView} src={user?.foto} alt="" />
            <p className={styles.txtBtn}>PERFIL</p>
          </div>
        </div>
      :
        <div className={styles.botonesView}>
          <div className={styles.btn} onClick={clickHome}>
            <img className={styles.iconView} src={user?.foto} alt="" />
            <p className={styles.txtBtn}>MI PERFIL</p>
          </div>
        </div>}
    </div>
  );
};

export default NavTree;
