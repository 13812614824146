import style from "./Seleccion.module.css"
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { getUser, editarPerfil } from "../../actions";
import logo from "../../assets/Logo.svg"
import filled from "../../assets/filled.png"

export default function Listo() {
    const usuario = JSON.parse(localStorage.getItem("dataUser"))

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const continuar = async () => {
        const response = await dispatch(editarPerfil(usuario.id, usuario))
        localStorage.setItem("dataUser", JSON.stringify(response))
        navigate("/home")
    }

    const onClickHome = () => {
        navigate("/")
    }

    return (
        <div className={style.container}>
            <div className={style.general}>
                <div className={style.contLogo}>
                    <img className={style.logo} src={logo} alt="Big Sport Data" />
                </div>
                <div className={style.listo}>
                    <div className={style.interL}>
                        <img className={style.filled} src={filled} alt="success" />
                        <h1 className={style.regNameL}>TODO LISTO</h1>
                        <div className={style.conText}>
                            <span className={style.linkTextL}>
                                Gracias por completar tu información de usuario. Te damos la bienvenida a Big Sport Data
                            </span>
                        </div>
                        <button onClick={() => continuar()} className={style.btn}>
                            Continuar
                        </button>
                    </div>
                </div>
                <p className={style.copyright}>© 2024. Big Sport Data Todos los derechos reservados.</p>
            </div>
        </div>
    )
}