import React, { useEffect, useState, useRef } from 'react';
import styles from './Mensajes.module.css';
import iconUser from "../../assets/iconUser.png";
import axios from 'axios';
import { URL } from '../../link';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import ModalNotificaciones from "../ModalNotificaciones/ModalNotificaciones.jsx";
import lupa from "../../assets/lupa.png";
import { calcularEdad, formatoLegible } from '../funciones.js';
import puntos from "../../assets/puntos.png";
import multimedia from "../../assets/multimedia.png";
import enviarI from "../../assets/enviar.png";
import { getUltimoMensaje, formFecha } from "../funciones.js";
import ModalUser from '../ModalUser/ModalUser.jsx';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ModalMetrica from "../ModalMetrica/ModalMetrica.jsx";

const Mensajes = ({ 
    user, seguidos, mensajes, enviarMensajes, 
    clickMessage, dispatcherAmigos, notificacionesBool, 
    userBool, logOut, inEdit, inVisitor, obtenerMensajes,
    desactivarNotificacion
}) => {
    const [destinatario, setDestinatario] = useState({});
    const [mensaje, setMensaje] = useState('');
    const [destinatarioSeleccionado, setDestinatarioSeleccionado] = useState(null);
    const [metricaBool, setMetricaBool] = useState(false); // Aquí definimos el estado
    const [metricaValor, setMetricaValor] = useState(null); // Aquí definimos el estado para el valor de la métrica
    const messagesRef = useRef(null);
    const params = useParams();
    const { id } = params;  

    useEffect(() => {
        if (id) {
            const amiSeleccionado = seguidos.find(ami => ami.id === id);
            if (amiSeleccionado) {
                selectDestinatario(amiSeleccionado);
            }
        }
    }, [id, seguidos]);

    useEffect(() => {
        const savedMessages = localStorage.getItem('mensajes');
        if (savedMessages) {
            try {
                dispatcherAmigos(JSON.parse(savedMessages));
            } catch (error) {
                console.error('Error al cargar los mensajes desde localStorage:', error);
            }
        }
    }, []);
    useEffect(() => {
    const handleDocumentClick = (event) => {
      if (notificacionesBool && event.target.closest('.modal-notificaciones') === null || userBool && event.target.closest('.modal-user') === null) {
        desactivarNotificacion();
      }
    };
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [notificacionesBool, desactivarNotificacion, userBool]);

    useEffect(() => {
        localStorage.setItem('mensajes', JSON.stringify(mensajes)); 
    }, [mensajes]);

    useEffect(() => {
        dispatcherAmigos(); 
    }, []);

    const enviar = async (e) => {
        e.preventDefault();
        if (mensaje.trim() !== "") {
            const data = {
                Remitente: user.id,
                Destinatario: destinatario.id,
                contenido: mensaje,
            };
            enviarMensajes(data);
            setMensaje(""); 
        }
    };

    const formatoTiempo = (fecha) => {
        return formatDistanceToNow(new Date(fecha), { addSuffix: true, locale: es });
    };

    const selectDestinatario = (ami) => {
        setDestinatario(ami);
        obtenerMensajes(user.id);
        setDestinatarioSeleccionado(ami.id);
    };

    const filtrarMensajes = (you) => {
        return mensajes.filter(
            (msg) => msg.RemitenteId === you || msg.DestinatarioId === you
        );
    };

    const cantidadMensajesNoLeidos = (remitenteId) => {
        return mensajes.filter((msg) => msg.RemitenteId === remitenteId && !msg.leido).length;
    };

    const confirmarMensajeLeido = async (remitenteId) => {
        const mensajeNoLeido = mensajes.filter((msg) => msg.RemitenteId === remitenteId && !msg.leido);
        for (let i = 0; i < mensajeNoLeido.length; i++) {
            try {
                await axios.put(`${URL}/mensaje/leido/${mensajeNoLeido[i].id}`);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            enviar(e);
        }
    };
    

    useEffect(() => {
        if (messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    }, [mensajes, destinatarioSeleccionado]);
    return (
        <div className={styles.container}>
            {/* Lista de amigos */}
            <div className={Object.keys(destinatario).length === 0 ? styles.amigos : styles.amigosOculto}>
                <div className={styles.conTitle}>
                    <h3 className={styles.title}>Mensajes</h3>
                </div>
                
                <div>
                    <div className={styles.contInp}>
                        <input className={styles.buscador} type="text" placeholder='Buscar conversación' />
                        <div className={styles.iconoContainer}>
                            <img src={lupa} alt="Buscar" className={styles.icono} />
                        </div>
                    </div>
                    <ul className={styles.lista}>
                        {seguidos.map((ami) => (
                            ami.id !== user.id && (
                                <li 
                                    key={ami.id} 
                                    className={`${styles.contAmi} ${destinatarioSeleccionado === ami.id ? styles.destinatarioSeleccionado : ''}`} 
                                    onClick={() => selectDestinatario(ami)}
                                >
                                    <div className={styles.user}>
                                        <img className={styles.foto} src={ami.foto || iconUser} alt="Usuario" />
                                        <div className={styles.infoMsg}>
                                            <div className={styles.estado}>
                                                <h4 className={styles.nombre}>{ami.nombre}</h4>
                                                <p className={styles.time}>{getUltimoMensaje(mensajes, ami.id)?.createdAt && formFecha(getUltimoMensaje(mensajes, ami.id)?.createdAt)}</p>
                                            </div>
                                            <h4 className={styles.msjPreview}>{getUltimoMensaje(mensajes, ami.id)?.contenido}</h4>
                                        </div>
                                    </div>
                                </li>
                            )
                        ))}
                    </ul>
                </div>
            </div>

            {/* Bandeja de mensajes */}
            <div className={styles.bandeja}>
                {Object.keys(destinatario).length > 0 && (
                    <div className={styles.containerMessage}>
                        <p className={styles.back} onClick={() => setDestinatario({})}>{`< Regresar`}</p>
                        <div className={styles.userHistory}>
                            <img className={styles.foto} src={destinatario.foto} alt="Destinatario" />
                            <div className={styles.contName}>
                                <h4 className={styles.name}>{destinatario.nombre}</h4>
                                <p className={styles.posicion}>{formatoLegible(destinatario.posicion)} - {calcularEdad(destinatario.dia, destinatario.mes, destinatario.anio)} años</p>
                                <p className={styles.posicion}>{destinatario.direccion}, {destinatario.origen}</p>
                            </div>
                            <button className={styles.btnV} onClick={() => inVisitor(destinatario.id)}>Ver perfil</button>
                            <img className={styles.icon} src={puntos} alt="Opciones" />
                        </div>
                        <ul className={styles.messages} ref={messagesRef}>
                            {filtrarMensajes(destinatario.id).map((msg) => (
                                <li key={msg.id} className={msg.DestinatarioId === user.id ? styles.listMsgA : styles.listMsg}>
                                    <div className={styles.msgRow}>
                                        <img className={styles.fotoB} src={msg.Remitente.foto || iconUser} alt="Foto" />
                                    </div>
                                    <div className={msg.DestinatarioId === user.id ? styles.contMsgA : styles.contMsg}>
                                        <div className={msg.DestinatarioId === user.id ? styles.conMenA : styles.conMen}>
                                            <p className={msg.DestinatarioId === user.id ? styles.txtA : styles.txt}>{msg.contenido}</p>
                                        </div>
                                        <span className={styles.time}>{formatoTiempo(msg.updatedAt)}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className={styles.envio}>
                            <div className={styles.contTextArea}>
                                <img className={styles.fotoB} src={user.foto} alt="Tu foto" />
                                <textarea
                                    placeholder='Tu mensaje'
                                    value={mensaje}
                                    className={styles.cuadroTxt}
                                    onChange={(e) => setMensaje(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                ></textarea>
                            </div>
                            <div className={styles.contBtn}>
                                <button className={styles.btnC}>
                                    <img className={styles.iconB} src={multimedia} alt="Multimedia" />
                                    Agregar multimedia
                                </button>
                                <button className={styles.btnE} onClick={enviar}>
                                    <img className={styles.iconC} src={enviarI} alt="Enviar" />
                                    Enviar mensaje
                                </button>
                            </div>
                        </div>
                    </div>
    )}
    </div>
    {/* Mostrar el modal si está activado */}
    {notificacionesBool && <ModalNotificaciones clickMessage={clickMessage} />}
    {userBool && <ModalUser logOut={logOut} inEdit={inEdit} />}
    {metricaBool && <ModalMetrica setMetricaBool={setMetricaBool} metricaV={metricaValor} user={user} />}
</div>
);
};

export default Mensajes;
