import axios from "axios"
import { URL } from "../../link.js"
import style from "./Sesion.module.css"
import { useState } from "react"
import { useNavigate } from 'react-router-dom'
import logo from "../../assets/Logo.svg"
import ojo from "../../assets/ojo.png"
import { useDispatch } from "react-redux"
import { getUser } from "../../actions/index.js"

export default function Sesion(props) {
    const [correo, setCorreo] = useState("")
    const [contrasena, setContrasena] = useState("")
    const [ocultar, setOcultar] = useState(true)
    const [aviso, setAviso] = useState("")

    const navigate = useNavigate();

    const dispatch = useDispatch()

    const onChanceInputs = (e) => {
        if (e.target.name === "correo") {
            setCorreo(e.target.value)
        }
        if (e.target.name === "contrasena") {
            setContrasena(e.target.value)
        }
    }
    const sesion = async () => {
        try {
            let data = {
                correo: correo,
                contrasena: contrasena
            }
            const res = await axios.post(`${URL}/usuario/sesion`, data)
            await dispatch(getUser(res.data.id))
            window.parent.postMessage({ type: 'dataUser', data: res.data }, '*');
            if (res.data.rol === null) {
                navigate("/seleccion")
            }
            else if (res.data.rol === "admin") {
                localStorage.setItem("dataUser", JSON.stringify(res.data))
                navigate("/admin")
            } else {
                localStorage.setItem("dataUser", JSON.stringify(res.data))
                props.logSesion(res.data.id)
            }
        } catch (error) {
            if(error.response){
                setAviso(error.response.data.message)
            } else {
                setAviso(error.message)
            }
        }
    }

    const onClickRegistro = () => {
        navigate("/register")
    }

    const onClickHome = () => {
        navigate("/")
    }


    return (
        <div className={style.container}>
            <div className={style.contSesion}>
                <div className={style.contLogo} onClick={onClickHome}>
                    <img className={style.logo} src={logo} alt="Big Sport Data" />
                </div>
                <div className={style.sesion}>
                    <div className={style.inter}>
                        <h1 className={style.regName}>INICIA SESION</h1>
                        <div className={style.conText}>
                            <span className={style.linkText}>
                                O si no tienes una cuenta,
                            </span>
                            <span className={style.linked} onClick={() => onClickRegistro()}>
                                crea una cuenta nueva
                            </span>
                        </div>
                        <div className={style.inputs}>
                            <p className={style.etiqueta}>Correo Electronico</p>
                            <input type="text" className={style.inp} name="correo" onChange={onChanceInputs}></input>
                        </div>
                        <div className={style.inputs}>
                            <p className={style.etiqueta}>Contraseña</p>
                            <div className={style.contInp}>
                                <input type={ocultar ? "password" : "text"} className={style.inp} name="contrasena" onChange={onChanceInputs} />
                                <div className={style.iconoContainer} onClick={() => setOcultar(!ocultar)}>
                                    <img src={ojo} alt="" className={style.icono} />
                                </div>

                            </div>
                        </div>
                        <span className={style.aviso}>{aviso}</span>
                        <button onClick={() => sesion()} className={style.btn}>
                            Iniciar sesión
                        </button>

                        <div className={style.contRecu}>
                            <span className={style.recuperacion} onClick={() => navigate("/recuperacion")}>¿Olvisate tu contraseña?</span>
                        </div>
                    </div>
                </div>
                <p className={style.copyright}>© 2024. Big Sport Data Todos los derechos reservados.</p>
            </div>
        </div>
    )
}