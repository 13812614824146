import axios from "axios";
import { URL } from "../../link.js"
import style from "./Register.module.css";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { validarCorreo, validarContrasena, compararContraseña, validarStrings } from './functions'
import logo from "../../assets/Logo.svg"
import ojo from "../../assets/ojo.png"
import ModalPolitica from "./ModalPolitica.jsx";


export default function Register() {
  const [correo, setCorreo] = useState("");
  const [contrasena, setContrasena] = useState("");
  const [nombre, setNombre] = useState("");
  const [errors, setErrors] = useState({ correo: "No puede quedar vacio", contrasena: "No puede quedar vacio", nombre: "No puede quedar vacio", repetirContrasena: "No puede quedar vacio" })
  const [hasErrors, setHasErrors] = useState(false);
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();
  const [aceptaTerminos, setAceptaTerminos] = useState(false);
  const [ocultar, setOcultar] = useState(true)
  const [ocultarB, setOcultarB] = useState(true)
  const [aviso, setAviso] = useState("")
  const [modalBool, setModalBool] = useState(false)


  useEffect(() => {
    const tieneErrores = Object.values(errors).some((error) => error !== "");
    setHasErrors(tieneErrores);
  }, [errors]);

  const onChanceInputs = (e) => {
    if (e.target.name === "nombre") {
      const nuevoNombre = e.target.value;
      setNombre(nuevoNombre);
      const nombreError = validarStrings(nuevoNombre) ? "" : "El Nombre no puede estar vacío o contener caracteres especiales o números, max 69 caracteres";
      setErrors((prevErrors) => ({ ...prevErrors, nombre: nombreError }));
    }
    if (e.target.name === "correo") {
      const nuevoCorreo = e.target.value;
      setCorreo(nuevoCorreo);
      const correoError = validarCorreo(nuevoCorreo) ? "" : "El Correo no es Valido";
      setErrors((prevErrors) => ({ ...prevErrors, correo: correoError }));
    }
    if (e.target.name === "contrasena") {
      const nuevaContrasena = e.target.value;
      setContrasena(nuevaContrasena);
      const contrasenaError = validarContrasena(nuevaContrasena) ? "" : "La contraseña debe contener entre 8 y 25 caracteres, mayúscula, minúscula, número o simbolo";
      setErrors((prevErrors) => ({ ...prevErrors, contrasena: contrasenaError }));
    }
    if (e.target.name === "repetirContrasena") {
      const nuevaRepetirContrasena = e.target.value;
      const repetirContrasenaError = compararContraseña(nuevaRepetirContrasena, contrasena) ? "" : "La contraseña no coincide";
      setErrors((prevErrors) => ({ ...prevErrors, repetirContrasena: repetirContrasenaError }));
    }

    const tieneErrores = Object.values(errors).some((error) => error !== "");
    setHasErrors(tieneErrores);
  };

  const onClickSesion = () => {
    navigate("/sesion")
  }

  const onClickHome = () => {
    navigate("/")
  }

  const handleAceptaTerminosChange = (e) => {
    setAceptaTerminos(e.target.checked);
    setAviso("")
  };


  const registro = async () => {
    if (!aceptaTerminos) {
      setAviso("Acepta los terminos y condiciones")
      return;
    }

    setLoading(false)
    try {
      let data = {
        nombre: nombre,
        correo: correo,
        contrasena: contrasena,
        rol: "Ninguno"
      };

      const res = await axios.post(`${URL}/usuario/registro`, data);
      if (res?.data) {
        setLoading(true)
        navigate("/confirmar")
      }
    } catch (error) {
      setLoading(true)
      console.log(error);
      if (error.response) {
        setAviso(error.response.data.message)
      } else {
        setAviso(error.message)
      }
    }
  };

  return (
    <div className={style.container}>
      <div className={style.register}>
        <div className={style.contLogo} onClick={onClickHome}>
          <img className={style.logo} src={logo} alt="Big Sport Data" />
        </div>
        <div className={style.sesion}>
          <div className={style.inter}>
            <h1 className={style.regName}>CREA UNA NUEVA CUENTA</h1>
            <div className={style.conText}>
              <span className={style.linkText}>
                O si ya tienes una cuenta,
              </span>
              <span className={style.linked} onClick={() => onClickSesion()}>
                inicia sesión
              </span>
            </div>
            <div className={style.inputs}>
              <p className={style.etiqueta}>Nombre completo</p>
              <input placeholder="Nombre" type="text" className={style.inp} name="nombre" onChange={onChanceInputs}></input>
              <p className={style.aviso}>{errors.nombre}</p>
            </div>
            <div className={style.inputs}>
              <p className={style.etiqueta}>Correo electronico</p>
              <input placeholder="Correo" type="text" className={style.inp} name="correo" onChange={onChanceInputs}></input>
              <p className={style.aviso}>{errors.correo}</p>
            </div>
            <div className={style.inputs}>
              <p className={style.etiqueta}>Contraseña</p>
              <div className={style.contInp}>
                <input type={ocultar ? "password" : "text"} placeholder="Contraseña" className={style.inp} name="contrasena" onChange={onChanceInputs} />
                <div className={style.iconoContainer} onClick={() => setOcultar(!ocultar)}>
                  <img src={ojo} alt="" className={style.icono} />
                </div>
              </div>
              <p className={style.aviso}>{errors.contrasena}</p>
            </div>
            <div className={style.inputs}>
              <p className={style.etiqueta}>Confirmma tu contraseña</p>
              <div className={style.contInp}>
                <input type={ocultarB ? "password" : "text"} placeholder="Repetir contraseña" className={style.inp} name="repetirContrasena" onChange={onChanceInputs} />
                <div className={style.iconoContainer} onClick={() => setOcultarB(!ocultarB)}>
                  <img src={ojo} alt="" className={style.icono} />
                </div>
              </div>
              <p className={style.aviso}>{errors.repetirContrasena}</p>
            </div>
            <span className={style.avisoB}>{aviso}</span>
            <button onClick={() => registro()} className={style.btn} disabled={hasErrors}>
              Registrarse
            </button>
            <div className={style.contCheck}>
              <input
                type="checkbox"
                className={style.checkbox}
                id="aceptaTerminos"
                checked={aceptaTerminos}
                onChange={handleAceptaTerminosChange}
              />
              <label htmlFor="aceptaTerminos" className={style.labelCheckbox}>
                <span className={style.linkTextCheck}>
                  Acepto los
                </span>
                <span className={style.linkedCheck} onClick={() => setModalBool(!modalBool)}>
                  terminos y condicionnes
                </span>
              </label>
            </div>
          </div>
        </div>
        <p className={style.copyright}>© 2024. Big Sport Data Todos los derechos reservados.</p>
      </div>
      {modalBool && <ModalPolitica setModalBool={setModalBool}/>}
    </div>
  );
}
