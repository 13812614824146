import style from "./Seleccion.module.css"
import { useState } from "react"
import { useNavigate } from 'react-router-dom'
import logo from "../../assets/Logo.svg"
import RadioButton from "../../assets/RadioButton.png"
import RadioButtonOk from "../../assets/RadioButtonOk.png"
import Swal from 'sweetalert2'

export default function Seleccion() {
    const [tipo, setTipo] = useState("")
    const usuario = JSON.parse(localStorage.getItem("dataUser"))
    const [aviso, setAviso] = useState("")

    

    const navigate = useNavigate();

    const onChanceInputs = (e) => {
        if (e === "Jugador") {
            setTipo(e)
        }
        if (e === "Otro") {
            setTipo(e)
        }
    }
    const continuar = () => {
        if (tipo === "") {
            setAviso("Debes seleccionar un rol para continuar")
        } else {
            usuario.rol = tipo
            localStorage.setItem("dataUser", JSON.stringify(usuario))
            if (tipo === "Jugador") {
                navigate("/basica")
            } else {
                navigate("/intereses")
            }
        }
    }

    const onClickHome = () => {
        navigate("/")
    }

    return (
        <div className={style.container}>
            <div className={style.general}>
                <div className={style.contLogo}>
                    <img className={style.logo} src={logo} alt="Big Sport Data" />
                </div>
                <div className={style.sesion}>
                    <div className={style.inter}>
                        <h1 className={style.regName}>SELECCIONA TU TIPO DE CUENTA</h1>
                        <div className={style.conText}>
                            <span className={style.linkText}>
                                Dependiendo del tipo de cuenta podrás acceder a herramientas y beneficios exclusivos para ti.
                            </span>
                        </div>
                        <div className={style.inputs} onClick={() => onChanceInputs("Jugador")}>
                            <div className={style.info}>
                                <p className={style.etiqueta}>Jugador</p>
                                <span className={style.textInfo}>
                                    Si eres un jugador activo selecciona esta opción.
                                </span>
                            </div>
                            <img src={tipo === "Jugador" ? RadioButtonOk : RadioButton} alt="seleccion" className={style.icon} />
                        </div>
                        <div className={style.inputs} onClick={() => onChanceInputs("Otro")}>
                            <div className={style.info}>
                                <p className={style.etiqueta}>Otro</p>
                                <span className={style.textInfo}>
                                    Si eres un aficionado, reclutador, entrenador u otro selecciona esta opción.
                                </span>
                            </div>
                            <img src={tipo === "Otro" ? RadioButtonOk : RadioButton} alt="seleccion" className={style.icon} />
                        </div>
                        <div className={style.contAviso}>
                            <p className={style.avisoB}>{aviso !== "" && aviso}</p>
                        </div>
                        <button onClick={() => continuar()} className={style.btn}>
                            Continuar
                        </button>
                    </div>
                </div>
                <p className={style.copyright}>© 2024. Big Sport Data Todos los derechos reservados.</p>
            </div>
        </div>
    )
}